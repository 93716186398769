import {
  ListFill18Icon,
  NewspaperFill18Icon,
} from "@vygruppen/spor-icon-react";
import { Flex } from "@vygruppen/spor-react";
import {
  getLocalStorageOploggTodoTabsSettings,
  setLocalStorageOploggTodoTabsSettings,
} from "api/dropsLocalStorage";
import {
  Tab,
  TabsWindows,
} from "features/CenterContent/shared/Tabs/TabsWindows";
import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import Oplogg from "./Oplogg";
import {
  OploggProvider,
  useNotifications as useOploggNotifications,
  useSetNotifications as useSetOploggNotifications,
} from "./Oplogg/useOploggContext";
import Todo from "./Todo";
import {
  TodoProvider,
  useSetNotifications as useSetTodoNotifications,
  useNotifications as useTodoNotifications,
} from "./Todo/useTodoContext";

export const CloseButtonWrapper = styled.div`
  grid-area: close;
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 3rem;
  border-radius: 6px;
  padding: 3px 5px;
  z-index: 1;
`;

type CollapsibleWrapperProps = {
  $isOpen?: boolean;
};

// thusanarul TODO: This needs to be rewritten to reflect the new design
export const CollapsibleWrapper = styled.div<CollapsibleWrapperProps>`
  display: ${(props) => (props.$isOpen === true ? "flex" : "none")};
  grid-area: collapsablemerged;
  padding-right: 6px;
  box-sizing: border-box;
  min-height: 0; // https://css-tricks.com/preventing-a-grid-blowout/
  background-color: ${({ theme }) => theme.colorBackgroundMain};
  z-index: 1;
`;

const TODO_OPLOGG_TABS: Tab[] = [
  {
    id: "oplogg",
    icon: <NewspaperFill18Icon />,
    text: "Operativ logg",
  },
  {
    id: "todo",
    icon: <ListFill18Icon />,
    text: "Todo",
  },
];

const Content = () => {
  const theme = useTheme();

  const [selectedTabIds, setSelectedTabIds] = useState(
    getLocalStorageOploggTodoTabsSettings(),
  );

  useEffect(() => {
    setLocalStorageOploggTodoTabsSettings(selectedTabIds);
  }, [selectedTabIds]);

  const oploggNotifications = useOploggNotifications();
  const todoNotifications = useTodoNotifications();
  const setOploggNotifications = useSetOploggNotifications();
  const setTodoNotifications = useSetTodoNotifications();

  return (
    <CollapsibleWrapper $isOpen>
      <Flex
        flexDirection="column"
        backgroundColor={theme.colorBackgroundSecondary}
        borderLeft={`1px solid ${theme.colorBorder}`}
        overflowY="auto"
        position="relative"
      >
        <Flex justifyContent="space-between">
          <TabsWindows
            tabs={TODO_OPLOGG_TABS}
            selectedTabIds={selectedTabIds}
            setSelectedTabIds={setSelectedTabIds}
            justifyContent="space-between"
            margin="12px"
            allowSplit
            hideTabIndex={1}
            notifications={[oploggNotifications, todoNotifications]}
            setNotifications={(value: number[]) => {
              setOploggNotifications(value[0]);
              setTodoNotifications(value[1]);
            }}
          />
        </Flex>
        <Flex overflowY="auto" flex="1">
          {selectedTabIds.includes("oplogg") && <Oplogg />}
          {selectedTabIds.includes("todo") && <Todo />}
        </Flex>
      </Flex>
    </CollapsibleWrapper>
  );
};

export const TodoOplogg = () => (
  <OploggProvider>
    <TodoProvider>
      <Content />
    </TodoProvider>
  </OploggProvider>
);
