import { Button } from "@vygruppen/spor-react";

import { ArrowRightOutline24Icon } from "@vygruppen/spor-icon-react";
import { AlertsCounter } from "shared/components/dataDisplay/AlertsCounter";
import styled from "styled-components";
import { Tab } from "./TabsWindows";

const ButtonStyled = styled(Button)`
  span {
    margin-left: 0px;
  }
`;

export const SplitButton = ({
  tabs,
  setSelectedTabIds,
  notifications,
}: {
  tabs: Tab[];
  setSelectedTabIds: (tabIds: string[]) => void;
  notifications: number;
}) => (
  <ButtonStyled
    size="sm"
    variant="ghost"
    onClick={() => {
      setSelectedTabIds(tabs.map((it) => it.id));
    }}
    rightIcon={<ArrowRightOutline24Icon />}
    display="flex"
    gap={0.5}
  >
    Todo
    <AlertsCounter alerts={notifications} severity="neutral" />
  </ButtonStyled>
);
