import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Flex, Text, useToast } from "@vygruppen/spor-react";
import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { MessageList } from "features/CenterContent/RoleContent/InternalMessage/shared/MessageList";
import {
  InternalMessageRequest,
  InternalMessageResponse,
  internalMessagesSchema,
} from "features/CenterContent/RoleContent/InternalMessage/types";
import {
  internalMessagesFormToRequest,
  internalMessagesResponseToForm,
} from "features/CenterContent/RoleContent/InternalMessage/utils/form";
import { INTERNAL_EVENTS_QUERY_KEY } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewButtonAndModal";
import {
  Stop as StopSimple,
  operationalIdentifierJsonToTrainIdentifier,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { BadgeText as BadgeTextStation } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/StationEventModal";
import { BadgeText } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { z } from "zod";

const INTERNAL_MESSAGES_DETAILS_QUERY_KEY = "internalMessagesDetails";

type InternalMessageType = {
  isGlobal: boolean;
  isTrain: boolean;
  isStation: boolean;
};

const getInternalMessageType = (
  train?: TrainIdentifier,
  stations: StopSimple[] = [],
): InternalMessageType => ({
  isGlobal: !train && stations.length === 0,
  isTrain: !!train,
  isStation: stations.length >= 1,
});

const makeTitle = (
  internalMessageType: InternalMessageType,
  stations: StopSimple[],
) => {
  const { isGlobal, isTrain } = internalMessageType;
  if (isGlobal) return `Oppdater global intern melding`;
  if (isTrain) return `Oppdater intern melding til tog`;
  return `Oppdater intern melding til ${stations.length >= 2 ? "stasjoner" : "stasjon"}`;
};
const InternalMessagesSchema = z.object({
  internalMessages: internalMessagesSchema,
});

type InternalMessages = z.infer<typeof InternalMessagesSchema>;

const getTrain = (event: InternalMessageResponse) =>
  event.relevantTrain
    ? operationalIdentifierJsonToTrainIdentifier(event.relevantTrain)
    : undefined;

const getStations = (event: InternalMessageResponse) =>
  event.relevantStops.map((it) => ({
    id: it.ids[0].id,
    name: it.ids[0].name ?? it.ids[0].id,
  }));

const EditInternalMessageModalContent = ({
  setModalOpen,
  events,
}: {
  setModalOpen: (value: boolean) => void;
  events: InternalMessageResponse[];
}) => {
  const event = events[0];
  const train = getTrain(event);
  const stations = getStations(event);
  const internalMessageType = getInternalMessageType(train, stations);
  const { isGlobal, isTrain, isStation } = internalMessageType;
  const formMethods = useForm({
    resolver: zodResolver(InternalMessagesSchema),
    defaultValues: {
      internalMessages: internalMessagesResponseToForm(events),
    },
  });

  const { mutate: submitRequest, status: submitStatus } = useMutation({
    mutationFn: (data: InternalMessageRequest) =>
      mutationFnPOST<undefined, InternalMessageRequest>(
        `${getBackendUrl()}/global-information/${event.uuid}/change`,
        data,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [INTERNAL_EVENTS_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [INTERNAL_MESSAGES_DETAILS_QUERY_KEY, event.uuid],
      });
    },
  });

  const onSubmit: SubmitHandler<InternalMessages> = ({ internalMessages }) => {
    const request = internalMessagesFormToRequest(
      internalMessages,
      train,
      stations,
    );
    if (!request) return;
    submitRequest(request);
  };

  return (
    <FormProvider {...formMethods}>
      <ActionModal
        title={makeTitle(internalMessageType, stations)}
        titleBadge={isGlobal ? "Uavhengig" : undefined}
        actionTitle="Send melding"
        onClose={() => setModalOpen(false)}
        onSubmit={formMethods.handleSubmit(onSubmit)}
        isLoading={submitStatus === "pending"}
        isSuccess={submitStatus === "success"}
        isError={submitStatus === "error"}
        successMessage="Hendelse endret"
        failureMessage="Kunne ikke endre hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer"
      >
        <ErrorBoundary>
          <Flex flexDir="column" gap="30px" w="100%">
            {isTrain && <BadgeText selectedTrain={train!} />}
            {isStation && <BadgeTextStation selectedStops={stations!} />}
            <MessageList
              formField="internalMessages"
              collapsed={false}
              maxHeight={undefined}
            />
            {isGlobal && (
              <Text>
                <b>OBS!</b> Globale meldinger blir synlig for all personell på
                alle linjer
              </Text>
            )}
          </Flex>
        </ErrorBoundary>
      </ActionModal>
    </FormProvider>
  );
};

const EditInternalMessageModal = ({
  setModalOpen,
  uuid,
}: {
  setModalOpen: (value: boolean) => void;
  uuid: string;
}) => {
  const { data, isLoading } = useQuery({
    queryKey: [INTERNAL_MESSAGES_DETAILS_QUERY_KEY, uuid],
    staleTime: 0,
    queryFn: ({ signal }) =>
      axiosClient
        .get<InternalMessageResponse[]>(
          `${getBackendUrl()}/global-information/uuid/${uuid}`,
          {
            signal,
          },
        )
        .then((res) => res.data.reverse()),
  });
  const toast = useToast();
  const unSuccessful = !data || data.length === 0;
  useEffect(() => {
    if (isLoading) return;
    if (unSuccessful) {
      toast({ variant: "error", text: `Noe gikk galt ved innlasting av data` });
      setModalOpen(false);
    }
  }, [data]);
  if (isLoading || unSuccessful) return null;
  return (
    <EditInternalMessageModalContent
      events={data}
      setModalOpen={setModalOpen}
    />
  );
};

export default EditInternalMessageModal;
