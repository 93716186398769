import {
  CustomTexts,
  globalEventFormSchema,
  GlobalEventRequest,
  VisibilityFormSchema,
  VisibilityRequest,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { internalMessagesFormToRequest } from "features/CenterContent/RoleContent/InternalMessage/utils/form";
import { formatTime } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/createRequestUtils";
import { z } from "zod";

export const contextsForGlobalWarning = [
  "DROPS",
  // "PASSENGER_INFORMATION_SYSTEM",
  "TRAIN_DRIVER",
  "CUSTOMER_ADVISOR",
  "CONDUCTOR",
  "VY_FRONT_PAGE",
  "DROPS_LOG",
];

const getRequestVisibility = (
  visibility: VisibilityFormSchema,
): VisibilityRequest => ({
  type: "OPEN_ENDED",
  fromTime: formatTime(visibility.fromTime),
});

const getTexts = (customTexts: CustomTexts) => {
  if (
    customTexts.texts.ENG &&
    (customTexts.texts.ENG.title.length > 0 ||
      customTexts.texts.ENG?.description.length > 0)
  ) {
    return customTexts.texts;
  }
  return { NOB: { ...customTexts.texts.NOB } };
};

export const formToGlobalInformationRequest = (
  formState: z.infer<typeof globalEventFormSchema>,
  referencedIncidentId?: string,
): GlobalEventRequest => ({
  globalInformationType: "GLOBAL_GENERAL_WARNING",
  visibility: getRequestVisibility(formState.visibility),
  incidentIds: referencedIncidentId ? [referencedIncidentId] : [],
  countryCode: "NO",
  customTexts: [
    {
      contexts: formState.customTexts.contexts,
      texts: getTexts(formState.customTexts),
    },
  ],
  internalMessage: internalMessagesFormToRequest(formState.internalMessages),
});
