import { Tooltip } from "@chakra-ui/react";
import { ZonedDateTime } from "@internationalized/date";
import { format } from "date-fns";
import {
  GlobalEvent,
  Visibility,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { InternalMessageResponse } from "features/CenterContent/RoleContent/InternalMessage/types";
import { EventCategory } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/Badges";
import { Stop } from "features/CenterContent/VehicleDetails/TrainDetails/useExistingTrainCancelledEvents";
import {
  Duration,
  InfraStructureInformation,
} from "shared/types/infrastructureResponse";

export type DisplayDuration = {
  fromTime: ZonedDateTime;
  toTime: ZonedDateTime | null;
};

export const getDisplayDurationFromVisibility = (
  visibility: Visibility,
): DisplayDuration | null => {
  switch (visibility.type) {
    case "TIMED":
      return {
        fromTime: visibility.fromTime,
        toTime: visibility.toTime,
      };
    case "OPEN_ENDED":
      return {
        fromTime: visibility.fromTime,
        toTime: null,
      };
    case "CONSTANT":
    default:
      return null;
  }
};

export const getDisplayDuration = (
  duration: Duration,
): DisplayDuration | null => {
  switch (duration.type) {
    case "TIMED":
      return {
        fromTime: duration.from_time,
        toTime: duration.to_time,
      };
    case "OPEN_ENDED":
      return {
        fromTime: duration.from_time,
        toTime: null,
      };
    case "PERIODIC": {
      if (duration.periods.length === 0) return null;

      const from = duration.periods.sort(
        (a, b) => a.fromTime.toDate().valueOf() - b.fromTime.toDate().valueOf(),
      )[0];
      const to = duration.periods.sort(
        (a, b) => b.toTime.toDate().valueOf() - a.toTime.toDate().valueOf(),
      )[0];

      return {
        fromTime: from.fromTime,
        toTime: to.toTime,
      };
    }
    default:
      return null;
  }
};

export const sortByDisplayDuration = (
  a: InfraStructureInformation,
  b: InfraStructureInformation,
) => {
  const durationA = getDisplayDuration(a.durations);
  const durationB = getDisplayDuration(b.durations);

  const fromTimeA = durationA?.fromTime.toDate().valueOf();
  const fromTimeB = durationB?.fromTime.toDate().valueOf();

  if (fromTimeA === undefined || fromTimeB === undefined) return 0;

  return fromTimeA - fromTimeB;
};

export const getDisplayDurationString = (
  displayDuration: DisplayDuration | null,
) => {
  if (displayDuration === null) return "Ukjent tidsrom.";

  if (!displayDuration.toTime)
    return `Fra kl. ${format(displayDuration.fromTime.toDate(), "HH:mm, dd.MM.yyyy")}`;

  return `Fra ${format(displayDuration.fromTime.toDate(), "dd.MM.yyyy")} - ${format(
    displayDuration.toTime.toDate(),
    "dd.MM.yyyy",
  )}`;
};

export const sortByVisibility = (a: GlobalEvent, b: GlobalEvent): number => {
  if (a.visibility.type === "CONSTANT" || b.visibility.type === "CONSTANT") {
    return 0;
  }
  const fromTimeA = a.visibility.fromTime.toDate().valueOf();
  const fromTimeB = b.visibility.fromTime.toDate().valueOf();

  return fromTimeB - fromTimeA;
};

export const getCategory = (event: InternalMessageResponse): EventCategory => {
  if (event.relevantTrain) return "train";
  if (event.relevantStops.length > 0) return "station";
  switch (event.relatedEventUuidCategory) {
    case "train":
      return "trainEvent";
    case "station":
      return "stationEvent";
    case "infrastructure":
      return "infrastructure";
    case "global":
      return "global";
    default:
      return "internal";
  }
};

const getStopName = (stop: Stop) => stop?.ids[0]?.name ?? stop?.ids[0]?.id;

export const getBadgeItem = (
  event: InternalMessageResponse,
): string | undefined => {
  const identifier = event.relevantTrain?.operational_identifier;
  return identifier ?? getStopName(event.relevantStops[0]);
};

export const getBadgeExtra = (event: InternalMessageResponse) => {
  const numStops = event.relevantStops.length;
  if (numStops <= 1) return null;
  return (
    <Tooltip
      label={event.relevantStops
        .slice(1)
        .map((it) => getStopName(it))
        .join(", ")}
      hasArrow
      shouldWrapChildren
    >
      +{numStops - 1}
    </Tooltip>
  );
};
