import {
  ButtonColors,
  ButtonSeverity,
} from "shared/components/buttons/SemanticButton";
import { DefaultTheme } from "styled-components";

export const SeverityColors = (
  severity: ButtonSeverity,
  theme: DefaultTheme,
): ButtonColors => {
  switch (severity) {
    case "alarm":
      return {
        severityTextMain: theme.colorAlarmTextTertiary,
        severity: theme.colorAlarm,
        severityFill: theme.colorAlarm,
        severityFillPulse: theme.colorAlarmPulse,
      };
    case "secondaryAlarm":
      return {
        severityTextMain: theme.colorSecondaryAlarmTextTertiary,
        severity: theme.colorAlarmSecondary,
        severityFill: theme.colorSecondaryAlarm,
        severityFillPulse: theme.colorSecondaryAlarmPulse,
      };
    case "warning":
      return {
        severityTextMain: theme.colorWarningTextTertiary,
        severity: theme.colorWarning,
        severityFill: theme.colorWarning,
        severityFillPulse: theme.colorWarningPulse,
      };
    case "info":
      return {
        severityTextMain: theme.colorInfoTextTertiary,
        severity: theme.colorInfo,
        severityFill: theme.colorInfo,
        severityFillPulse: theme.colorInfoPulse,
      };
    case "success":
      return {
        severityTextMain: theme.colorSuccessTextTertiary,
        severity: theme.colorSuccess,
        severityFill: theme.colorSuccess,
        severityFillPulse: theme.colorSuccessPulse,
      };
    case "neutral":
    default:
      return {
        // TODO: colorNeutralOutline is used instead of colorNeutralTextTertiary
        // since its too hard to see it consider solving this differently
        severityTextMain: theme.colorNeutralOutline,
        severity: theme.colorNeutral,
        severityFill: theme.colorNeutral,
        severityFillPulse: theme.colorNeutralPulse,
      };
  }
};

export const getButtonStylePrimary = (
  severity: ButtonSeverity,
  theme: DefaultTheme,
) => {
  const colors = SeverityColors(severity, theme);

  return {
    color: colors.severityTextMain,
    backgroundColor: colors.severityFill,
    outlineColor: "none",
    "&:hover": {
      color: colors.severityTextMain,
      backgroundColor: colors.severityFillPulse,
      outlineColor: "none",
    },
    "&:active": {
      color: colors.severityTextMain,
      backgroundColor: colors.severity,
      outlineColor: "none",
    },
    "&:disabled": {
      color: colors.severityTextMain,
      backgroundColor: colors.severityFill,
      outline: "none",
      opacity: 0.5,
    },
  };
};
