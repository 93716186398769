import { ModalHeader } from "@chakra-ui/react";
import {
  Button,
  ButtonGroup,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from "@vygruppen/spor-react";
import { GlobalEventModal } from "features/CenterContent/RoleContent/GlobalEvents/GlobalEventModal";
import { InfrastructureEventCreatedResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { FC, useState } from "react";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";

type MajorEventWarningProps = {
  response: InfrastructureEventCreatedResponse;
  onClose: () => void;
};

export const MajorEventWarning: FC<MajorEventWarningProps> = ({
  response,
  onClose,
}) => {
  const [showGlobalModal, setShowGlobalModal] = useState(false);
  return showGlobalModal ? (
    <GlobalEventModal onClose={onClose} relatedInfrastructureEvent={response} />
  ) : (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalBody>
          <DropsStaticAlert variant="warning" title="OBS!">
            <Text>
              Hendelsen du opprettet ser ut som et stort avvik. Vurder å gi det
              ekstra synlighet ved å opprette en Global driftsmelding i tillegg.
            </Text>
          </DropsStaticAlert>
        </ModalBody>
        <ModalFooter
          justifyContent="space-between"
          width="100%"
          pb={4}
          flexDir="column"
        >
          <Divider w="calc(100% + 36px)" mb="10px" />
          <ButtonGroup justifyContent="space-between" mt={2} w="100%">
            <Button type="button" variant="tertiary" onClick={onClose}>
              Ikke nå
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={() => setShowGlobalModal(true)}
            >
              Opprett global driftsmelding
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
