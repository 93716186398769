import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { internalMessagesFormDefault } from "features/CenterContent/RoleContent/InternalMessage/utils/form";
import {
  formSchema,
  FormSchema,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/formSchema";
import { StationEventModal } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/StationEventModal";
import { StationInfoRequest } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/types/stationInformationRequest";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useStretchBuilder } from "stores/useStretchBuilder";

export const StationEvent: FC = () => {
  const [stretchBuilderModalState] = useStretchBuilder((state) => [
    state.stretchBuilderModalState,
  ]);

  const event =
    (stretchBuilderModalState.type === "station" &&
      stretchBuilderModalState.eventInfo) ||
    undefined;

  const isEditMode = !!event;

  const defaultValues = {
    stationForm: isEditMode
      ? event
      : {
          stationInformationType: undefined,
          stops: [],
          internalMessages: internalMessagesFormDefault(),
        },
  };

  const formMethods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: "onSubmit",
  });

  const { mutate: postStationInfo, status: postStationInfoStatus } =
    useMutation({
      mutationFn: (data: StationInfoRequest) =>
        mutationFnPOST<undefined, StationInfoRequest>(
          `${getBackendUrl()}/station-information${isEditMode ? `/${event.uuid}/change` : ""}`,
          data,
        ),
    });

  if (stretchBuilderModalState.type !== "station") return null;

  return (
    <FormProvider {...formMethods}>
      {!stretchBuilderModalState.stretchBuilderMode ? (
        <StationEventModal
          onFormSubmit={postStationInfo}
          formSubmitStatus={postStationInfoStatus}
          isEditMode={isEditMode}
        />
      ) : (
        <div />
      )}
    </FormProvider>
  );
};
