import { Flex, Tooltip } from "@chakra-ui/react";
import {
  Button,
  ButtonGroup,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@vygruppen/spor-react";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import { InternalMessage } from "features/CenterContent/RoleContent/InternalMessage/shared/InternalMessage";
import { FormEvent, useEffect, useRef } from "react";
import { DropsBadge } from "shared/components/badge/DropsBadge";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import { FCWC } from "shared/utils/FCWC";
import { AllOrNone, EndsWith } from "shared/utils/objectUtils";
import styled from "styled-components";

export const ModalScrollableBody = styled(ModalBody)`
  ${ObtrusiveScrollCss};
  overflow-y: auto;
  max-height: 65vh;
`;

type ActionModalProps = {
  title: string;
  actionTitle: string;
  titleBadge?: string;
  cancelTitle?: string;
  onClose: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  // ↓ If enabled then submit is clickable when disabled, but runs this function instead
  onSubmitDisabled?: (e: FormEvent<HTMLFormElement>) => void;
  isSubmitDisabled?: boolean;
  onSubmitTooltip?: string;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  successMessage: string;
  failureMessage: string;
} & AllOrNone<{
  internalMessagesFormField?: EndsWith<".internalMessages">;
  internalMessagesMode?: "create" | "edit";
}>;

export const ActionModal: FCWC<ActionModalProps> = ({
  title,
  actionTitle,
  titleBadge,
  cancelTitle,
  onClose,
  onSubmit,
  onSubmitDisabled,
  isSubmitDisabled = false,
  onSubmitTooltip,
  isLoading,
  isSuccess,
  isError,
  successMessage,
  failureMessage,
  children,
  internalMessagesFormField: internalMessageFormField,
  internalMessagesMode: internalMessageMode,
}) => {
  useEffect(() => {
    if (isSuccess) {
      setTimeout(onClose, 1000);
    }
  }, [isSuccess]);

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () =>
    scrollRef.current?.scrollBy({
      top: 10000,
      behavior: "smooth",
    });
  function isInfoboxVisible() {
    const container = scrollRef.current;
    const infobox = document.getElementById("infoBoxActionModal");
    if (!container || !infobox) return true;
    const containerRect = container.getBoundingClientRect();
    const infoboxRect = infobox.getBoundingClientRect();

    const isWithinVerticalBounds =
      infoboxRect.top >= containerRect.top &&
      infoboxRect.bottom <= containerRect.bottom + 50; // 50px extra leeway

    return isWithinVerticalBounds;
  }
  const internalMessageEnabled = useFeatureFlag("internalMessage").enabled; // Enabled: Show "Intern melding" footer at train/station/infrastructure/global

  return (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pt={5} pb={3} style={{ textAlign: "left" }}>
          <Flex flexDirection="column" gap={1}>
            {title}
            {titleBadge && (
              <DropsBadge semantics="alarm" width="fit-content">
                {titleBadge}
              </DropsBadge>
            )}
          </Flex>
        </ModalHeader>
        <Divider w="100%" />
        <ModalCloseButton />
        <form
          onSubmit={(e) => {
            if (isSubmitDisabled && onSubmitDisabled) {
              onSubmitDisabled(e);
              e.nativeEvent.preventDefault(); // Prevent page refresh
              return;
            }
            onSubmit(e);
            e.nativeEvent.preventDefault(); // Prevent page refresh
          }}
          id={`${title}-form`}
        >
          <ModalScrollableBody
            ref={scrollRef}
            pt="30px"
            minH="25vh"
            position="relative" // Used for absolute position for internal message
          >
            <Flex h="100%" flexDir="column">
              <Stack alignItems="center">{children}</Stack>
            </Flex>
          </ModalScrollableBody>
          {internalMessageEnabled && internalMessageFormField && (
            <InternalMessage
              formField={internalMessageFormField}
              isEditMode={internalMessageMode === "edit"}
            />
          )}
        </form>

        <ModalFooter
          justifyContent="space-between"
          width="100%"
          pb={4}
          flexDir="column"
        >
          <Divider w="calc(100% + 36px)" mb="10px" />
          {isError && (
            <DropsStaticAlert w="100%" variant="error">
              {failureMessage}
            </DropsStaticAlert>
          )}
          {isSuccess && (
            <DropsStaticAlert
              w="100%"
              variant="success"
              data-testid={`${title}-success-msg`}
            >
              {successMessage}
            </DropsStaticAlert>
          )}
          <ButtonGroup justifyContent="space-between" mt={2} w="100%">
            <Button
              type="button"
              variant="tertiary"
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              {cancelTitle || "Avbryt"}
            </Button>
            <Tooltip label={onSubmitTooltip}>
              <Button
                aria-invalid={isSubmitDisabled}
                form={`${title}-form`}
                isDisabled={
                  isSuccess || (isSubmitDisabled && !onSubmitDisabled)
                }
                variant="primary"
                onClick={(e) => {
                  if (isInfoboxVisible()) return;
                  scrollToBottom();
                  e.preventDefault();
                }}
                type="submit"
                isLoading={isLoading}
              >
                {isError ? "Prøv igjen" : actionTitle}
              </Button>
            </Tooltip>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
