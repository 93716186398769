import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { getBackendUrl } from "api/common";
import { queryFnPOST } from "api/tanStackQuery/helpers";
import TrainInfoFormModal, {
  RequestBody,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { OperationalInformationTrainResponse } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/operationalInformationTrainResponse";
import { FC } from "react";

type CreateTrainInfoProps = {
  train: TrainIdentifier;
  setModalOpen: (v: boolean) => void;
  trainInfoWithOpenState: string[];
};

export const CreateTrainInfoModal: FC<CreateTrainInfoProps> = ({
  train,
  setModalOpen,
  trainInfoWithOpenState,
}) => {
  const queryClient = useQueryClient();

  const {
    mutate: postTrainInfo,
    status: postTrainInfoStatus,
    reset: resetMutation,
  } = useMutation({
    mutationFn: ({ body }: RequestBody) =>
      queryFnPOST<OperationalInformationTrainResponse>({
        url: `${getBackendUrl()}/trainInformation/train`,
        body,
        signal: undefined,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["routeChangedEvents", train],
      });
    },
  });

  return (
    <TrainInfoFormModal
      mode="create"
      selectedTrain={train}
      onSubmit={postTrainInfo}
      submitStatus={postTrainInfoStatus}
      trainInfoWithOpenState={trainInfoWithOpenState}
      onClose={() => {
        resetMutation();
        setModalOpen(false);
      }}
    />
  );
};
