import { useMutation } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { GlobalEventRequest } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";

export const usePostGlobalInfo = (uuid?: string) =>
  useMutation({
    mutationFn: (data: GlobalEventRequest) =>
      mutationFnPOST<undefined, GlobalEventRequest>(
        `${getBackendUrl()}/global-information${uuid ? `/${uuid}/change` : "/create"}`,
        data,
      ),
  });
