import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnPOST } from "api/tanStackQuery/helpers";
import { FC } from "react";

import { TrainIdentifierWithIncidentIds_JSON } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";
import TrainInfoBatchModal, {
  RequestBodyCreateBatch,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoBatchModal";

export type OpenTrainBatchResponse = {
  error?: string;
  train_identifiers?: TrainIdentifierWithIncidentIds_JSON[];
};

type CreateTrainInfoProps = {
  selectedTrains: AffectedTrain[];
  setModalOpen: (v: boolean) => void;
  updateSelectedTrains: (selectedTrain: AffectedTrain) => void;
  addPendingTrains: (trains: TrainIdentifierWithIncidentIds_JSON[]) => void;
};

export const CreateTrainInfoBatchModal: FC<CreateTrainInfoProps> = ({
  selectedTrains,
  setModalOpen,
  updateSelectedTrains,
  addPendingTrains,
}) => {
  const queryClient = useQueryClient();
  const {
    mutate: postTrainInfo,
    status: postTrainInfoStatus,
    reset: resetMutation,
  } = useMutation({
    mutationFn: ({ body }: RequestBodyCreateBatch) =>
      queryFnPOST<OpenTrainBatchResponse>({
        url: `${getBackendUrl()}/trainInformation/trains`,
        body,
        signal: undefined,
      }),
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ["routeChangedEvents", ...selectedTrains],
      });
      if (response?.train_identifiers) {
        addPendingTrains(response.train_identifiers);
      }
    },
  });

  return (
    <TrainInfoBatchModal
      selectedTrains={selectedTrains}
      onSubmit={postTrainInfo}
      submitStatus={postTrainInfoStatus}
      updateSelectedTrains={updateSelectedTrains}
      onClose={() => {
        resetMutation();
        setModalOpen(false);
      }}
    />
  );
};
