import {
  AltTransportFill18Icon,
  AltTransportOutline18Icon,
  CodeFill18Icon,
  CodeOutline18Icon,
  HistoryFill18Icon,
  HistoryOutline18Icon,
  KlippekortFill18Icon,
  KlippekortOutline18Icon,
  MapFill18Icon,
  MapOutline18Icon,
  PersonFill18Icon,
  PersonOutline18Icon,
  PositionFill18Icon,
  PositionOutline18Icon,
} from "@vygruppen/spor-icon-react";
import ISirkView from "features/CenterContent/RoleContent/ISirk/ISirkView";
import Lokleder from "features/CenterContent/RoleContent/Lokleder/Lokleder";
import ReasonCodes from "features/CenterContent/RoleContent/Lokleder/ReasonCodes/ReasonCodes";
import { Personellstyrer } from "features/CenterContent/RoleContent/Personellstyrer/Personellstyrer";
import TrainMap from "features/CenterContent/RoleContent/TrainMap/TrainMap";
import Vaktleder from "features/CenterContent/RoleContent/Vaktleder/Vaktleder";
import VehicleMap from "features/CenterContent/RoleContent/VehicleMap/VehicleMap";
import Checklist from "features/CenterContent/RoleContent/VehicleChecklist/Checklist";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import { HistoryOverview } from "features/History/HistoryOverview";
import { ReactElement } from "react";
import { UserRole } from "shared/types/roles";
import styled, { useTheme } from "styled-components";

const TabWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const TrainMapTab = () => (
  <TabWrapper>
    <TrainMap />
  </TabWrapper>
);

const ISirkTab = () => (
  <TabWrapper>
    <ISirkView />
  </TabWrapper>
);

const VehicleMapTab = () => (
  <TabWrapper>
    <VehicleMap />
  </TabWrapper>
);

const HistoryTab = () => (
  <TabWrapper>
    <HistoryOverview />
  </TabWrapper>
);

const ChecklistsTab = () => (
  <TabWrapper>
    <Checklist />
  </TabWrapper>
);

export const ReasonCodesTab = () => {
  const theme = useTheme();
  return (
    <TabWrapper
      style={{
        backgroundColor: theme.colorBackgroundSecondary,
        border: `1px solid ${theme.colorBorder}`,
        color: theme.colorTextMain,
        padding: "1rem",
        borderRadius: "6px",
      }}
    >
      <ReasonCodes />
    </TabWrapper>
  );
};

export enum TabIds {
  START = "start",
  TRAINMAP = "togkart",
  GPSMAP = "gpskart",
  REASONCODES = "reasoncodes",
  CHECKLIST = "kvitteringsliste",
  HISTORY = "oversikt",
}

type TabInfo = {
  id: TabIds;
  name: string;
  content: ReactElement;
  iconActive: ReactElement;
  iconInactive: ReactElement;
};

export const useTabsForRole = (role: UserRole): TabInfo[] | null => {
  const checklistFillOutsEnabled = useFeatureFlag("checklistFillOuts").enabled;

  const LoklederInfo = {
    id: TabIds.START,
    name: role,
    content: <Lokleder />,
    iconActive: <PersonFill18Icon />,
    iconInactive: <PersonOutline18Icon />,
  };

  const TrainMapTabInfo = {
    id: TabIds.TRAINMAP,
    name: "Togkart",
    content: <TrainMapTab />,
    iconActive: <MapFill18Icon />,
    iconInactive: <MapOutline18Icon />,
  };

  const TrainMapTabInfoVaktleder = {
    ...TrainMapTabInfo,
    id: TabIds.START,
    content: <Vaktleder />,
  };

  const VehicleMapTabInfo = {
    id: TabIds.GPSMAP,
    name: "GPS-kart",
    content: <VehicleMapTab />,
    iconActive: <PositionFill18Icon />,
    iconInactive: <PositionOutline18Icon />,
  };

  const VehicleMapTabInfoMateriellstyrer = {
    ...VehicleMapTabInfo,
    id: TabIds.START,
  };

  const PersonellstyrerInfo = {
    id: TabIds.START,
    name: "Personell",
    content: <Personellstyrer />,
    iconActive: <PersonFill18Icon />,
    iconInactive: <PersonOutline18Icon />,
  };

  const ReasonCodesTabInfo = {
    id: TabIds.REASONCODES,
    name: "Årsakskoder",
    content: <ReasonCodesTab />,
    iconActive: <CodeFill18Icon />,
    iconInactive: <CodeOutline18Icon />,
  };

  const ISirkTabInfo = {
    id: TabIds.START,
    name: "I-Sirk",
    content: <ISirkTab />,
    iconActive: <AltTransportFill18Icon />,
    iconInactive: <AltTransportOutline18Icon />,
  };

  const HistoryTabInfo = {
    id: TabIds.HISTORY,
    name: "Oversikt",
    content: <HistoryTab />,
    iconActive: <HistoryFill18Icon />,
    iconInactive: <HistoryOutline18Icon />,
  };

  const ChecklistsTabInfo = {
    id: TabIds.CHECKLIST,
    name: "Kvitteringer",
    content: <ChecklistsTab />,
    iconActive: <KlippekortFill18Icon />,
    iconInactive: <KlippekortOutline18Icon />,
  };

  switch (role) {
    case UserRole.lokleder1:
    case UserRole.lokleder2:
      return [
        LoklederInfo,
        HistoryTabInfo,
        TrainMapTabInfo,
        VehicleMapTabInfo,
        ...(checklistFillOutsEnabled ? [ChecklistsTabInfo] : []),
      ];
    case UserRole.personellstyrerkond:
    case UserRole.personellstyrerlok:
    case UserRole.personellstyrervest:
      return [PersonellstyrerInfo, HistoryTabInfo, VehicleMapTabInfo];
    case UserRole.materiellstyrerlokal:
    case UserRole.materiellstyrerregion:
      return [
        VehicleMapTabInfoMateriellstyrer,
        HistoryTabInfo,
        TrainMapTabInfo,
        ...(checklistFillOutsEnabled ? [ChecklistsTabInfo] : []),
      ];
    case UserRole.transportleder:
    case UserRole.vaktleder:
      return [
        TrainMapTabInfoVaktleder,
        HistoryTabInfo,
        VehicleMapTabInfo,
        ReasonCodesTabInfo,
      ];
    case UserRole.trafikkplanlegger:
      return [
        ISirkTabInfo,
        HistoryTabInfo,
        TrainMapTabInfo,
        VehicleMapTabInfo,
        ReasonCodesTabInfo,
      ];
    default:
      return null;
  }
};
