import { CalendarDate, getLocalTimeZone } from "@internationalized/date";
import {
  DatePicker,
  Flex,
  FormControl,
  HStack,
  SearchInput,
} from "@vygruppen/spor-react";
import { isFuture, isYesterday } from "date-fns";
import { format } from "date-fns/format";
import { DayPicker } from "features/VehicleList/DayPicker";
import { useEffect, useState } from "react";
import { dropsRole } from "stores/dropsRole";
import styled, { useTheme } from "styled-components";
import AlternativeTransportList from "./AlternativeTransportList/AlternativeTransportList";
import ReserveAlternativeTransportList from "./ReserveAlternativeTransportList/ReserveAlternativeTransportList";
import TrainList from "./TrainList/TrainList";
import VehicleTabs, { vehicleListTabs } from "./VehicleTabs";
import FilterButtons from "./common/FilterButtons";
import { useVehicleDate } from "./useVehicleDate";

const ListHeader = styled.div<{ hasSearchQuery: boolean }>`
  padding: 21px 12px
    ${({ hasSearchQuery }) => (hasSearchQuery ? "6px" : "16px")} 12px;
  align-items: baseline;
  position: relative;
  display: grid;
  gap: 3px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colorSeparationLine};
`;

const sxCenterDatePicker = {
  ".chakra-popover__content": { left: "-50px" },
  ".chakra-popover__arrow": { display: "none" },
};

export const calendarDateToday = () => {
  const todayDate = format(new Date(), "yyyy-MM-dd");
  const [yyyy, MM, dd] = todayDate.split("-").map((it) => parseInt(it, 10));
  return new CalendarDate(yyyy, MM, dd);
};

const showAllVehiclesBtnStates = (
  selectedDate: Date,
  currentSearchQuery: string,
) => {
  if (currentSearchQuery.length > 0) {
    return { disable: true, overrideCheck: true };
  }
  if (isYesterday(selectedDate)) {
    return { disable: true, overrideCheck: true };
  }

  if (isFuture(selectedDate)) {
    return { disable: true, overrideCheck: false };
  }
  return { disable: false, overrideCheck: false };
};

const VehicleList = () => {
  const { role: userRole } = dropsRole();

  const [searchQuery, setSearchQuery] = useState("");
  const vehicleDate = useVehicleDate(new Date());

  const isTrafikkplanlegger = userRole === "trafikkplanlegger";
  const [tabIndex, setTabIndex] = useState(0);

  const [showAllVehicles, setShowAllVehicles] = useState(false);

  useEffect(() => setTabIndex(0), [userRole]);

  useEffect(() => {
    if (isYesterday(vehicleDate.selectedDate)) {
      setShowAllVehicles(true);
    } else {
      setShowAllVehicles(false);
    }
  }, [vehicleDate.selectedDate]);

  const showAllVehiclesBtnState = showAllVehiclesBtnStates(
    vehicleDate.selectedDate,
    searchQuery,
  );

  return (
    <Flex flexDir="column" overflowY="auto">
      <ListHeader hasSearchQuery={searchQuery.length >= 2}>
        {isTrafikkplanlegger && <VehicleTabs onChange={setTabIndex} />}
        <label htmlFor="vehicle-search" style={{ margin: 0 }}>
          {vehicleListTabs[tabIndex].label}
        </label>
        <HStack>
          <SearchInput
            id="vehicle-search"
            label="Søk"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onReset={() => setSearchQuery("")}
          />
          {vehicleListTabs[tabIndex].value === "Tog" && (
            <FormControl sx={sxCenterDatePicker}>
              <DatePicker
                label="Dato"
                variant="base"
                defaultValue={calendarDateToday()}
                withPortal={false}
                onChange={(date) => {
                  if (!date) return;
                  vehicleDate.setSelectedDate(date.toDate(getLocalTimeZone()));
                }}
              />
            </FormControl>
          )}
          <DayPicker
            showChangeDayOptions={
              isTrafikkplanlegger && vehicleListTabs[tabIndex].value !== "Tog"
            }
            vehicleDate={vehicleDate}
          />
        </HStack>
        {isTrafikkplanlegger && vehicleListTabs[tabIndex].value !== "Tog" && (
          <FilterButtons
            showAllVehicles={showAllVehicles}
            setShowAllVehicles={setShowAllVehicles}
            disable={showAllVehiclesBtnState.disable}
            overrideCheck={showAllVehiclesBtnState.overrideCheck}
          />
        )}
      </ListHeader>

      {vehicleListTabs[tabIndex].value === "Tog" && (
        <TrainList
          searchQuery={searchQuery}
          isTrafficPlanner={isTrafikkplanlegger}
          selectedDate={vehicleDate.selectedDate}
        />
      )}
      {isTrafikkplanlegger && (
        <>
          {vehicleListTabs[tabIndex].value === "Buss" && (
            <AlternativeTransportList
              searchQuery={searchQuery}
              selectedDate={vehicleDate.selectedDate}
              showAllVehicles={showAllVehicles}
            />
          )}
          {vehicleListTabs[tabIndex].value === "Reservebuss" && (
            <ReserveAlternativeTransportList
              searchQuery={searchQuery}
              selectedDate={vehicleDate.selectedDate}
              showAllVehicles={showAllVehicles}
            />
          )}
        </>
      )}
    </Flex>
  );
};

export default VehicleList;
