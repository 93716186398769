import { PersonOutline24Icon } from "@vygruppen/spor-icon-react";
import { Heading, Text } from "@vygruppen/spor-react";
import styled from "styled-components";
import { getUser } from "api/drops/drops";
import { useEffect, useState } from "react";

const ProfileContainer = styled.div`
  position: relative;
  list-style: none;
  padding-right: 12px;
  overflow: auto;
  margin: 0;
  padding-top: 18px;
  padding-bottom: 18px;

  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
`;

const IconNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 0;
`;

const HeadingContainer = styled.div`
  margin-top: 2px;
`;

const PersonOutline24IconStyled = styled(PersonOutline24Icon)`
  align-self: center;
  margin-left: 16px;
  margin-right: 8px;
`;

const TextContainer = styled.div`
  margin-left: 48px;
`;

const fetchUserEmail = async () => {
  try {
    const response = await getUser();
    return response.data.email;
  } catch (error) {
    return null;
  }
};

const ProfileDisplay = () => {
  const [email, setEmail] = useState<string | null>(null);
  useEffect(() => {
    const fetchEmail = async () => {
      const userEmail = await fetchUserEmail();
      setEmail(userEmail);
    };

    fetchEmail();
  }, []);

  return (
    <ProfileContainer>
      <IconNameContainer>
        <PersonOutline24IconStyled />
        <HeadingContainer>
          <Heading as="h1" variant="sm" fontWeight="Bold">
            {
              (email ?? "")
                .replace(/\./g, " ")
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")
                .split("@")[0]
            }
          </Heading>
        </HeadingContainer>
      </IconNameContainer>

      <TextContainer>
        <Text variant="ls">{email}</Text>
      </TextContainer>
    </ProfileContainer>
  );
};

export default ProfileDisplay;
