import { FC, SetStateAction, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider,
  FormControl,
  HStack,
  Text,
  DatePicker,
  TimePicker,
  InfoSelect,
  Item,
  ItemLabel,
  TextLink,
  Textarea,
  Button,
  Combobox,
  Flex,
} from "@vygruppen/spor-react";
import { DateValue, getLocalTimeZone, today } from "@internationalized/date";
import {
  AddOutline30Icon,
  LinkOutFill18Icon,
} from "@vygruppen/spor-icon-react";
import { useMutation } from "@tanstack/react-query";
import {
  mutationFnPOST,
  mutationFnPUT,
  queryFnGET,
} from "api/tanStackQuery/helpers";
import { getBackendUrl } from "api/common";
import { FillOut } from "./FillOut";
import { VehicleSetVariant, VehicleSetVariants } from "./VehicleSetVariant";

interface NewChecklistModalProps {
  currentFillOut: FillOut | null;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

const filter_options = [
  { value: "bremseprøve", text: "Bremseprøve" },
  { value: "innsett-iks", text: "Innsett (IKS)" },
  { value: "uttak-uks", text: "Uttak (UKS)" },
];

const ChecklistModal: FC<NewChecklistModalProps> = ({
  currentFillOut,
  isOpen,
  onClose,
  refetch,
}) => {
  const [date, setDate] = useState<DateValue>(today(getLocalTimeZone()));
  const [time, setTime] = useState<string | undefined>(() => {
    const now = new Date();
    return now.toTimeString().slice(0, 5);
  });
  const [comment, setComment] = useState<string>("");
  const [vehicleSetVariants, setVehicleSetVariants] = useState<
    VehicleSetVariant[]
  >([]);
  const [fillOutType, setFillOutType] = useState<string | undefined>(undefined);
  const [rollingStockId, setRollingStockId] = useState<string | undefined>();
  const [vehicleType, setVehicleType] = useState<string | undefined>();
  const [trainOwner, setTrainOwner] = useState<string | undefined>();

  const [checklistAlert, setFillOutAlert] = useState<boolean>(false);
  const [vehicleAlert, setVehicleAlert] = useState<boolean>(false);
  const [ownerAlert, setOwnerAlert] = useState<boolean>(false);

  useEffect(() => {
    if (currentFillOut) {
      setTime(currentFillOut.executedAt.split("T")[1].slice(0, 5));
      setComment(currentFillOut.comment || "");
      setFillOutType(currentFillOut.checklistType);
      setRollingStockId(currentFillOut.rollingStock);
      setVehicleType(currentFillOut.vehicleType);
    }
  }, [currentFillOut]);

  function handleChangeTime(newTime: string | undefined) {
    const selectedTime = time ? time.slice(0, 5) : "00:00";
    const newTimeFormatted = newTime ? newTime.slice(0, 5) : "00:00";

    if (selectedTime === "00:00" && newTimeFormatted === "23:00") {
      setDate(date.subtract({ days: 1 }));
    } else if (selectedTime === "23:00" && newTimeFormatted === "00:00") {
      setDate(date.add({ days: 1 }));
    }

    setTime(newTimeFormatted);
  }

  const { mutate: putChecklist, status: putStatus } = useMutation({
    mutationFn: (data: any) =>
      mutationFnPUT<undefined, any>(
        `${getBackendUrl()}/checklist/fill-out/${currentFillOut?.fillOutId}`,
        data,
      ),
  });

  const { mutate: postChecklist, status: postStatus } = useMutation({
    mutationFn: (data: any) =>
      mutationFnPOST<undefined, any>(
        `${getBackendUrl()}/checklist/fill-out`,
        data,
      ),
  });

  const fetchVehicleSetVariants = async (dateParam: string) => {
    const response = await queryFnGET<VehicleSetVariants>({
      signal: undefined,
      url: `${getBackendUrl()}/rolling-stock/vehicle-set-variants?validAt=${dateParam}`,
    });

    return response ?? { vehicleSetVariants: [] };
  };

  const dateString = date.toString().split("T")[0];

  useEffect(() => {
    const fetchIds = async () => {
      const response = await fetchVehicleSetVariants(dateString);
      setVehicleSetVariants(response.vehicleSetVariants);
    };

    fetchIds();
  }, [date]);

  const adjustTimeToUTC = (inputTime: string | undefined): string => {
    if (!inputTime) return "00:00";
    const [hours, minutes] = inputTime.split(":").map(Number);
    const newDate = new Date();
    newDate.setHours(hours - 1, minutes); // Adjust time to UTC (1 hour before)
    return newDate.toTimeString().slice(0, 5);
  };

  const handleUpdate = () => {
    // TODO:
    // This could probably be handled better using a zod schema,
    // with validation errors using forms?
    if (!fillOutType) {
      setFillOutAlert(true);
    }

    if (!rollingStockId) {
      setVehicleAlert(true);
    }

    if (!trainOwner) {
      setOwnerAlert(true);
    }

    if (!trainOwner || !vehicleType || !fillOutType || !rollingStockId) {
      return;
    }

    // TODO: Should have its own type, and state should probably be held using forms with validation using zod.
    const checklistData = {
      companyId: trainOwner,
      rollingStock: rollingStockId,
      vehicleType,
      executedAt: `${date.toString()}T${adjustTimeToUTC(time)}Z`, // TODO: Does not include minutes or local time zone.
      checklistType: fillOutType,
      comment,
    };

    putChecklist(checklistData, {
      onSuccess: () => {
        refetch();
        onClose();
      },
    });
  };

  const handlePost = () => {
    if (!fillOutType) {
      setFillOutAlert(true);
    }

    if (!rollingStockId) {
      setVehicleAlert(true);
    }

    if (!trainOwner) {
      setOwnerAlert(true);
    }

    if (!fillOutType || !rollingStockId || !trainOwner) {
      return;
    }

    const checklistData = {
      executedAt: `${date.toString()}T${adjustTimeToUTC(time)}Z`, // TODO: Parsed value does not include minutes and is UTC (should be local time zone).
      companyId: trainOwner,
      checklistType: fillOutType,
      rollingStock: rollingStockId,
      vehicleType,
      comment,
    };

    postChecklist(checklistData, {
      onSuccess: () => {
        refetch();
        onClose();
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {currentFillOut ? "Endre Kvittering" : "Opprett kvittering"}
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody pb={6}>
          <FormControl>
            <Flex gap="5px" pt="20px">
              <Text fontWeight="bold" mb={4}>
                {currentFillOut ? "Opprettet av:" : ""}
              </Text>
              <Text>{currentFillOut?.executor}</Text>
            </Flex>
            <HStack mb={4}>
              <DatePicker
                label="Dato"
                width="50%"
                value={date}
                onChange={(value) =>
                  setDate(value ?? today(getLocalTimeZone()))
                }
                variant="base"
              />

              <TimePicker
                label="Tid"
                width="50%"
                onChange={(newTime) => handleChangeTime(newTime?.toString())}
              />
            </HStack>
            <HStack mb={4} justifyContent="flex-end">
              <TextLink
                variant="primary"
                size="md"
                onClick={() => {
                  window.open(
                    "https://nsbas.sharepoint.com/sites/Sjekklisterforkjrety/Lists/Sjekklister/AllItems.aspx",
                    "blank",
                  );
                }}
              >
                Se alle sjekklister
              </TextLink>
              <LinkOutFill18Icon />
            </HStack>
            <FormControl isInvalid={checklistAlert} pb={2}>
              <InfoSelect
                name="kvitteringType"
                label="Kvitteringsliste"
                placeholder="Velg type"
                value={fillOutType}
                items={filter_options}
                onChange={(value) => {
                  setFillOutType(value.toString());
                  setFillOutAlert(false);
                }}
              >
                {(item) => (
                  <Item key={item.text} textValue={item.text}>
                    <ItemLabel>{item.text}</ItemLabel>
                  </Item>
                )}
              </InfoSelect>
            </FormControl>
            <FormControl isInvalid={vehicleAlert} pb={2}>
              <Combobox
                leftIcon={<AddOutline30Icon />}
                label="Legg til kjøretøy-id"
                selectedKey={currentFillOut?.rollingStock}
                onSelectionChange={(value) => {
                  const setType = vehicleSetVariants.find(
                    (variant) => variant.setId === value,
                  )?.setType;

                  if (value && setType) {
                    setRollingStockId(value.toString());
                    setVehicleType(setType);
                  }

                  if (currentFillOut && value && setType) {
                    currentFillOut.rollingStock = value.toString();
                    currentFillOut.vehicleType = setType;
                  }

                  setVehicleAlert(false);
                }}
              >
                {vehicleSetVariants &&
                Array.isArray(vehicleSetVariants) &&
                vehicleSetVariants.length > 0 ? (
                  vehicleSetVariants.map((variant) => (
                    <Item key={variant.setId} textValue={variant.setId}>
                      <ItemLabel>{variant.setId}</ItemLabel>
                    </Item>
                  ))
                ) : (
                  <Item>
                    <ItemLabel>Ingen kjøretøy tilgjengelig</ItemLabel>
                  </Item>
                )}
              </Combobox>
            </FormControl>

            <FormControl isInvalid={ownerAlert}>
              <InfoSelect
                label="Selskap"
                defaultValue={
                  currentFillOut?.companyId
                    ? currentFillOut.companyId
                    : undefined
                }
                items={[
                  // TODO: Should be split out into a global collection for reusability.
                  // TODO: Also, verify mapped values.
                  { value: "110", label: "Vygruppen (Øst)" },
                  { value: "121", label: "Vy tog (Vest)" },
                  { value: "310", label: "Cargonet" },
                ]}
                onChange={(value) => {
                  setTrainOwner(value.toString());
                  setOwnerAlert(false);
                }}
              >
                {(item) => <Item key={item.value}>{item.label}</Item>}
              </InfoSelect>
            </FormControl>
            <Text pt={3}>Kommentar</Text>
            <Textarea
              label="Skriv evt kommentar her"
              value={comment}
              onChange={(e: { target: { value: SetStateAction<string> } }) =>
                setComment(e.target.value)
              }
              height="130px"
            />
          </FormControl>
        </ModalBody>
        <Divider />
        <ModalFooter pt={4}>
          <Flex justifyContent="space-between" width="100%">
            <Button variant="tertiary" onClick={onClose}>
              Avbryt
            </Button>
            <Button
              onClick={currentFillOut ? handleUpdate : handlePost}
              isLoading={postStatus === "pending" || putStatus === "pending"}
            >
              {currentFillOut ? "Lagre endringer" : "Opprett kvittering"}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChecklistModal;
