import styled from "styled-components";
import { Button } from "@vygruppen/spor-react";
import { ObtrusiveScrollCss } from "../../../shared/components/transitions/scroll";

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 6px;
  flex: 2;
`;

export const ButtonStyled = styled(Button)`
  &&& {
    padding: 0px;
    min-height: 0px;
    min-width: 0px;
  }
  > * > * > * {
    /* Why is it so nested Spor? */
    display: flex;
  }
`;

export const ResultListWrapper = styled.div`
  ${ObtrusiveScrollCss};
  overflow-y: scroll;
  width: 100%;
  height: 100%;
`;

export const ResultList = styled.ul`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

export const DateHeader = styled.div`
  padding: 5px 35px;
  background-color: ${({ theme }) => theme.colorBackgroundMain};
  border-bottom: 1px solid ${({ theme }) => theme.colorBorder};
  width: 100%;
`;
