import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { queryClient } from "api/tanStackQuery/queryClient";
import {
  GlobalEvent,
  globalEventSchema,
  globalEventsResponseSchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { useEffect } from "react";
import { isBeforeNow } from "shared/utils/dateTimeUtils";
import { useDropsWebsocket } from "websocket/drops/DropsWebsocketContext";

const isVisible = (message: GlobalEvent) => {
  switch (message.visibility.type) {
    case "CONSTANT":
      return message.visibility.visible;
    case "OPEN_ENDED":
      return isBeforeNow(message.visibility.fromTime);
    case "TIMED":
      return (
        isBeforeNow(message.visibility.fromTime) &&
        !isBeforeNow(message.visibility.toTime)
      );
    default:
      return true;
  }
};

const updateGlobalInfo = (
  message: GlobalEvent,
  prev: GlobalEvent[],
  shouldRemove: boolean,
) => {
  if (shouldRemove) {
    return prev.filter((info) => info.uuid !== message.uuid);
  }
  return [message, ...prev.filter((info) => info.uuid !== message.uuid)];
};

export const GLOBAL_EVENTS_QUERY_KEY = "globalEvents";

export const useGlobalInformation = () => {
  const globalEvents = useQuery({
    queryKey: [GLOBAL_EVENTS_QUERY_KEY],
    staleTime: 0,
    queryFn: ({ signal }) =>
      axiosClient
        .get<GlobalEvent[]>(
          `${getBackendUrl()}/global-information/visible/NO`,
          {
            signal,
          },
        )
        .then((res) => globalEventsResponseSchema.parse(res.data)),
  });

  const { message } = useDropsWebsocket("useGlobalInformation", [
    "GLOBAL_INFORMATION",
  ]);

  useEffect(() => {
    if (
      message.status === "received" &&
      message.data.topic === "GLOBAL_INFORMATION"
    ) {
      const globalInfoMessage = globalEventSchema.parse(message.data.message);
      const visible = isVisible(globalInfoMessage);
      queryClient.setQueryData<GlobalEvent[]>(
        [GLOBAL_EVENTS_QUERY_KEY],
        (prev) => {
          if (!prev) {
            return visible ? [globalInfoMessage] : [];
          }
          return updateGlobalInfo(globalInfoMessage, prev, !visible);
        },
      );
    }
  }, [message]);

  return { globalEvents };
};
