import { Text } from "shared/components/typography/TitleStyles";
import styled, { css } from "styled-components";

export const TabList = styled.ol`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-inline-start: 0;
  padding-inline-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: auto;
`;

const TabItemCss = css`
  color: ${({ theme }) => theme.colorTextMain};
  cursor: pointer;
  display: inline-block;
  list-style: none;
  padding: 0.5rem 0.75rem;
  margin: 0.25rem 0.25rem 0 0.25rem;
  border-top-left-radius: 0.7rem;
  border-top-right-radius: 0.7rem;
`;

export const InactiveTab = styled.li`
  ${TabItemCss};
  background-color: ${({ theme }) => theme.colorBackgroundTertiary};
  border-color: ${({ theme }) => theme.colorBackgroundMain};
  border-width: 1px 1px 0px 1px;
  &:hover {
    background-color: ${({ theme }) => theme.colorHoverItem};
    border-color: ${({ theme }) => theme.colorOutline};
  }
`;

export const ActiveTab = styled.li`
  ${TabItemCss};
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  border: 1px solid ${({ theme }) => theme.colorBorder};
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
`;

export const CloseTabs = styled.li`
  ${TabItemCss};
  background-color: transparent;
  border-radius: 99px;
  padding: 2px 8px;
  &:hover {
    background-color: ${({ theme }) => theme.colorHoverItem};
  }
`;

export const TabsContentWrapper = styled.div`
  display: flex;
  min-height: 53px;
  justify-content: end;
  flex-direction: column;
  position: relative;
  z-index: 1; /* Tabs are 1px on top of content */
`;

export const TabText = styled(Text)`
  display: flex;
  align-items: center;
  gap: 4px;
`;
