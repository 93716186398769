import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  ChoiceChip,
  Grid,
  Table,
  tokens,
} from "@vygruppen/spor-react";
import styled from "styled-components";
import { ObtrusiveScrollCss } from "../../../../shared/components/transitions/scroll";
import colors from "../../../../shared/theme/colors";

export const ChecklistCustomTableStyle = styled(Table)`
  padding-top: 6px;
  table {
    border-collapse: separate;
    border-spacing: 0 6px;
  }

  td,
  th {
    border-bottom: none;
    padding-left: 8px;
    padding-right: 6px;
    background-color: ${({ theme }) => theme.colorBackgroundTertiary};
  }

  th {
    background-color: ${({ theme }) => theme.colorBackgroundTertiary};
  }

  tr > td:first-of-type,
  tr > th:first-of-type {
    border-top-left-radius: ${tokens.default.size["border-radius"].xs};
    border-bottom-left-radius: ${tokens.default.size["border-radius"].xs};
  }

  tr > td:last-of-type,
  tr > th:last-of-type {
    border-top-right-radius: ${tokens.default.size["border-radius"].xs};
    border-bottom-right-radius: ${tokens.default.size["border-radius"].xs};
  }
`;

export const VisibilityIndicator = styled.div<{ $isVisible: boolean }>`
  width: 20px;
  height: 20px;
  background-color: ${({ $isVisible }) =>
    $isVisible
      ? tokens.default.color.alias.greenHaze
      : tokens.default.color.alias.brightRed};
  border-radius: 100%;
  border: solid 5px
    ${({ $isVisible }) =>
      $isVisible
        ? tokens.default.color.alias.coralGreen
        : tokens.default.color.alias.lightRed};
`;

export const AccordionButtonStyled = styled(AccordionButton)`
  display: grid !important;
  grid-template-columns: repeat(7, 1fr) 20px;
  background-color: ${({ theme }) => theme.colorBackgroundTertiary};
  border-radius: 5px !important;
  margin-top: 10px;
  padding-right: 0px !important;
`;

export const GridStyled = styled(Grid)`
  grid-template-columns: repeat(7, 1fr) 20px;
  padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${({ theme }) => theme.colorBackgroundTertiary};
  border-radius: 5px !important;
  font-weight: bold;
  width: 99%;
`;

export const AccordionPanelStyled = styled(AccordionPanel)`
  background-color: ${({ theme }) => theme.colorBackgroundTertiary};
  border-radius: 5px !important;
  margin-top: 2px;
`;

export const AccordionItemStyled = styled(AccordionItem)`
  outline: none !important;
  width: 99%;
  margin-left: auto;
  margin-right: auto;
`;

export const EmptyTableText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
`;

export const LinkOutButton = styled(Button)`
  display: flex;
  width: 160px;
  align-items: center;
`;

export const ChecklistHistoryWrapper = styled.div`
  container-type: inline-size;
  grid-area: 2 / 1 / 3 / 4;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  ${ObtrusiveScrollCss};
  scrollbar-gutter: stable;
  overflow-y: auto;
  height: 100%;
  border-radius: 6px;
  border: 1px solid ${colors.dimGrey};

  padding-left: 30px;
  padding-right: 30px;
  @container (width < 1200px) {
    .DontShowIfSmallWidth {
      display: none;
    }
  }
`;

export const StyledChoiceChip = styled(ChoiceChip)`
  & > * {
    display: flex;
    border-radius: 10px 10px 10px 10px !important;
  }
  display: flex;

  & > * * {
    font-weight: bold;
    display: flex;
    gap: 5px;
  }
`;

interface DetailsContainerProps {
  $borderColor?: string;
}

export const DetailsContainer = styled.div<DetailsContainerProps>`
  margin-left: 30px;
  border-left: 2px solid
    ${({ $borderColor, theme }) => $borderColor || theme.colorAlarm};
`;

// This is a workaround for the overflow issue in the search input
export const NoLabelOverflow = styled.div`
  display: flex;
  width: 50%;
  position: relative;
  & label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 54px); /* 54px is the width of the search icon */
  }
`;

// This is a workaround for the overflow issue in the button
export const NoDivOverflowButton = styled.div`
  & div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const LinkOutHideTextWhenNarrow = styled.div`
  max-width:
    80px,
  min-width: 0px;

    & div {
    width: 100%;
  }
  & button {
    width: 100%;
  }
`;

// Used to change font size of button
export const ChangeInternalFontSize = styled.div`
  & span {
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
