import { Divider } from "@chakra-ui/react";
import { Box, Flex, Text } from "@vygruppen/spor-react";
import { ReactNode, useEffect } from "react";
import { AlertsCounter } from "shared/components/dataDisplay/AlertsCounter";
import styled, { useTheme } from "styled-components";
import { CombineButton } from "./CombineButton";
import { SplitButton } from "./SplitButton";

export type Tab = {
  id: string;
  icon: ReactNode;
  text: string;
};

const TabBox = styled(Box)<{ cursor: string }>`
  cursor: ${({ cursor }) => cursor};
  &:hover {
    * {
      opacity: 1;
    }
  }
`;

const DividerAbs = styled(Divider)`
  position: absolute;
  top: 0px;
  left: 50%;
  height: 100%;
  z-index: 100;
`;

export const TabsWindows = ({
  tabs,
  selectedTabIds,
  setSelectedTabIds,
  justifyContent,
  margin,
  allowSplit,
  hideTabIndex,
  notifications,
  setNotifications,
}: {
  tabs: Tab[];
  selectedTabIds: string[];
  setSelectedTabIds: (tabIds: string[]) => void;
  justifyContent: "start" | "end" | "center" | "space-between";
  margin?: string;
  allowSplit?: boolean;
  hideTabIndex?: number;
  notifications?: number[];
  setNotifications?: (value: number[]) => void;
}) => {
  const theme = useTheme();
  const multipleTabs = selectedTabIds.length === 2;
  const showNotification = (tabId: string) =>
    !selectedTabIds.includes(tabId) && notifications;

  const hiddenTabNotificaiton =
    hideTabIndex && notifications ? notifications[hideTabIndex] : 0;

  useEffect(() => {
    if (setNotifications) setNotifications([0, 0]);
  }, [selectedTabIds]);
  return (
    <>
      <Flex
        justifyContent={multipleTabs ? "space-between" : justifyContent}
        gap={5}
        m={margin}
        mt={4}
        width="100%"
        pb={0}
        alignItems="center"
      >
        {tabs.map((it, index) => {
          if (!multipleTabs && hideTabIndex === index) return null;
          return (
            <TabBox
              key={it.id}
              p="0px"
              pb="0px"
              ml={it.id === "todo" ? "72px" : "0px"}
              height="30px"
              justifyContent="center"
              borderBottom={
                selectedTabIds.includes(it.id)
                  ? `1px solid ${theme.colorTextMain}`
                  : ""
              }
              cursor="pointer"
              onClick={
                multipleTabs ? undefined : () => setSelectedTabIds([it.id])
              }
            >
              <Flex gap={1} alignItems="center">
                <Flex
                  margin="auto"
                  opacity={selectedTabIds.includes(it.id) ? "1" : "0.5"}
                >
                  {it.icon}
                </Flex>
                <Text
                  fontWeight="600"
                  variant="xs"
                  opacity={selectedTabIds.includes(it.id) ? "1" : "0.5"}
                >
                  {it.text}
                </Text>
                {showNotification(it.id) && (
                  <AlertsCounter
                    alerts={notifications![index]}
                    severity="neutral"
                    opacity="0.5"
                  />
                )}
              </Flex>
            </TabBox>
          );
        })}
        <Box mt="-9px">
          {allowSplit &&
            (multipleTabs ? (
              <CombineButton
                tabs={tabs}
                setSelectedTabIds={setSelectedTabIds}
              />
            ) : (
              <SplitButton
                tabs={tabs}
                setSelectedTabIds={setSelectedTabIds}
                notifications={hiddenTabNotificaiton}
              />
            ))}
        </Box>
      </Flex>
      {multipleTabs && <DividerAbs orientation="vertical" />}
    </>
  );
};
