import { EditOutline18Icon } from "@vygruppen/spor-icon-react";
import { Flex, HStack, IconButton, Stack, VStack } from "@vygruppen/spor-react";
import { format } from "date-fns";
import { InternalMessageResponse } from "features/CenterContent/RoleContent/InternalMessage/types";
import { getVisibility } from "features/CenterContent/RoleContent/InternalMessage/utils/form";
import { Badges } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/Badges";
import {
  getBadgeExtra,
  getBadgeItem,
  getCategory,
  getDisplayDurationFromVisibility,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/utils";
import { DropsBadge } from "shared/components/badge/DropsBadge";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import { Text } from "shared/components/typography/TitleStyles";
import { dropsRole } from "stores/dropsRole";
import { useTheme } from "styled-components";

type InternalMessageEventsProps = {
  data: InternalMessageResponse[];
  onEditClick: (event: InternalMessageResponse) => void;
};

export const InternalMessageEventsList = ({
  data,
  onEditClick,
}: InternalMessageEventsProps) => {
  const theme = useTheme();
  const { isVaktleder } = dropsRole();
  if (data.length === 0)
    return (
      <NoDataMessage reason="Det er ikke registrert noen hendelser med denne filtreringen" />
    );
  return (
    <Stack gap={3}>
      {data.map((event) => {
        const text = event.customTexts[0].texts.NOB;
        const dropsBadgeItem = getBadgeItem(event);
        const dropsBadgeExtra = getBadgeExtra(event);
        const category = getCategory(event);
        return (
          <HStack
            key={event.uuid}
            border={`1px solid ${theme.colorOutline}`}
            borderRadius="sm"
            p={2}
            justifyItems="space-between"
            alignItems="flex-start"
            gap={4}
          >
            <VStack alignItems="flex-start" flex="1">
              <Flex gap={2}>
                {dropsBadgeItem && (
                  <DropsBadge semantics="info">{dropsBadgeItem}</DropsBadge>
                )}
                {dropsBadgeExtra && (
                  <DropsBadge semantics="info">{dropsBadgeExtra}</DropsBadge>
                )}
                <Text bold>{text.title}</Text>
              </Flex>
              <Badges
                eventCategory={category}
                displayDuration={getDisplayDurationFromVisibility(
                  getVisibility(event.visibility),
                )}
              />
              <Text>{text.description}</Text>
              <Text secondary>
                {format(
                  event.updatedAt,
                  "'Sist oppdatert' dd.MM.yyyy 'kl.' HH:mm",
                )}
              </Text>
            </VStack>
            {isVaktleder() && (
              <IconButton
                onClick={() => onEditClick(event)}
                size="sm"
                aria-label="Rediger"
                variant="ghost"
                icon={<EditOutline18Icon />}
              />
            )}
          </HStack>
        );
      })}
    </Stack>
  );
};
