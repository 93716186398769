import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Flex, Text, Textarea } from "@vygruppen/spor-react";
import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { InternalMessageRequest } from "features/CenterContent/RoleContent/InternalMessage/types";
import {
  internalMessagesFormDefault,
  internalMessagesFormToRequest,
} from "features/CenterContent/RoleContent/InternalMessage/utils/form";
import { INTERNAL_EVENTS_QUERY_KEY } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewButtonAndModal";
import { Stop } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { BadgeText as BadgeTextStation } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/StationEventModal";
import { BadgeText } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { SubmitHandler, useForm } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";
import { AllOrNone } from "shared/utils/objectUtils";
import { StretchBuilderModalState } from "stores/useStretchBuilder";
import { z } from "zod";

type InternalMessageType = {
  isGlobal: boolean;
  isTrain: boolean;
  isStation: boolean;
};

const getInternalMessageType = (
  train?: TrainIdentifier,
  stations: Stop[] = [],
): InternalMessageType => ({
  isGlobal: !train && stations.length === 0,
  isTrain: train !== undefined,
  isStation: stations.length >= 1,
});

const makeTitle = (
  internalMessageType: InternalMessageType,
  stations: Stop[],
) => {
  const { isGlobal, isTrain } = internalMessageType;
  if (isGlobal) return `Ny global intern melding`;
  if (isTrain) return `Ny intern melding til tog`;
  return `Ny intern melding til ${stations.length >= 2 ? "stasjoner" : "stasjon"}`;
};

type StationProps = {
  stations?: Stop[];
  allStopsSelected?: boolean;
  stretchBuilderModalState?: StretchBuilderModalState;
  setStretchBuilderModalState?: (value: StretchBuilderModalState) => void;
  onClose?: () => void;
};

const InternalMessageSchema = z.object({
  internalMessage: z.string().min(1, "Må fylles ut"),
});

type InternalMessage = z.infer<typeof InternalMessageSchema>;

const CreateInternalMessageModal = ({
  setModalOpen,
  train,
  stations = [],
  allStopsSelected,
  stretchBuilderModalState,
  setStretchBuilderModalState,
  onClose = () => {},
}: {
  setModalOpen: (value: boolean) => void;
  train?: TrainIdentifier;
} & AllOrNone<StationProps>) => {
  const internalMessageType = getInternalMessageType(train, stations);
  const { isGlobal, isTrain, isStation } = internalMessageType;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<InternalMessage>({
    resolver: zodResolver(InternalMessageSchema),
  });

  const { mutate: submitRequest, status: submitStatus } = useMutation({
    mutationFn: (data: InternalMessageRequest) =>
      mutationFnPOST<undefined, InternalMessageRequest>(
        `${getBackendUrl()}/global-information/create`,
        data,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [INTERNAL_EVENTS_QUERY_KEY],
      });
    },
  });

  const onSubmit: SubmitHandler<InternalMessage> = ({ internalMessage }) => {
    const form = internalMessagesFormDefault({ text: internalMessage });
    const request = internalMessagesFormToRequest(form, train, stations);
    if (!request) return;
    submitRequest(request);
  };

  return (
    <ActionModal
      title={makeTitle(internalMessageType, stations)}
      titleBadge={isGlobal ? "Uavhengig" : undefined}
      actionTitle="Send melding"
      onClose={() => {
        setModalOpen(false);
        onClose();
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={submitStatus === "pending"}
      isSuccess={submitStatus === "success"}
      isError={submitStatus === "error"}
      successMessage="Hendelse opprettet"
      failureMessage="Kunne ikke opprette hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer"
    >
      <ErrorBoundary>
        <Flex flexDir="column" gap={5} w="100%">
          {isTrain && <BadgeText selectedTrain={train!} />}
          {isStation && (
            <BadgeTextStation
              selectedStops={stations!}
              allStopsSelected={allStopsSelected!}
              stretchBuilderModalState={stretchBuilderModalState!}
              setStretchBuilderModalState={() => {
                // Workaround for navigating back to stretchBuilder
                setStretchBuilderModalState!({
                  type: "station",
                  stretchBuilderMode: true,
                });
                setModalOpen(false);
              }}
            />
          )}
          <Textarea
            {...register("internalMessage")}
            label="Skriv din melding..."
            resize="none"
          />
          <RenderErrorInPath
            mt="-12px"
            errors={errors}
            errorPath="internalMessage"
          />
          {isGlobal && (
            <Text>
              <b>OBS!</b> Globale meldinger blir synlig for all personell på
              alle linjer
            </Text>
          )}
        </Flex>
      </ErrorBoundary>
    </ActionModal>
  );
};

export default CreateInternalMessageModal;
