import { LinkBox } from "@chakra-ui/react";
import { FCWC } from "shared/utils/FCWC";
import { useTheme } from "styled-components";

type ElementProps = {
  selected: boolean;
  onClick?: () => void;
};
const ElementWrapper: FCWC<ElementProps> = ({
  selected,
  onClick,
  children,
}) => {
  const theme = useTheme();

  return (
    <LinkBox
      as="li"
      display="flex"
      gap={1}
      justifyContent="space-between"
      alignItems="center"
      p={2}
      borderBottom={`1px solid ${
        selected ? theme.colorBorder : theme.colorBorder
      }`}
      backgroundColor={selected ? theme.contentSelectedColor : "inherit"}
      _hover={{
        backgroundColor: theme.colorHoverItem,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {children}
    </LinkBox>
  );
};

export default ElementWrapper;
