import { useQuery } from "@tanstack/react-query";
import { Skeleton, Stack } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { FC } from "react";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";
import { InfrastructureEventType } from "shared/types/infrastructureResponse";

type RelatedIncidentProps = {
  incidentId: string;
};

export const RelatedIncident: FC<RelatedIncidentProps> = ({ incidentId }) => {
  const { data, status } = useQuery({
    queryKey: ["relatedIncident", incidentId],
    queryFn: ({ signal }) =>
      queryFnGET<InfrastructureEventType>({
        signal,
        url: `${getBackendUrl()}/infrastructure-event/by-incident-id/NO?incidentId=${incidentId}`,
      }),
  });

  const getContent = () => {
    switch (status) {
      case "pending":
        return <Skeleton height={6} width="100%" />;
      case "success":
        return (
          <DropsStaticAlert
            variant="info"
            width="100%"
            title="Oppsummering infrastrukturhendelse"
          >
            {data.infrastructureInformation.message}
          </DropsStaticAlert>
        );
      case "error":
      default:
        return (
          <DropsStaticAlert variant="error">
            Kunne ikke hente tilknyttet infrastrukturhendelse
          </DropsStaticAlert>
        );
    }
  };

  return getContent();
};
