import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Skeleton, SkeletonText, Stack } from "@vygruppen/spor-react";
import { LogLevel, log } from "api/cloudWatch";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import {
  TrainSeriesGrouping,
  buildTrainSeriesGroupForSingleIncident,
} from "features/CenterContent/RoleContent/AffectedTrains/utils/groupTrainSeries";
import {
  batchTrainFormToRequestBody,
  createInitialBatchFormStateFromTrainSeries,
} from "features/CenterContent/RoleContent/AffectedTrains/utils/utils";
import { useInfrastructureEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/hooks/useInfrastructureEvent";
import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";
import { TrainInfoBatchForm } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoBatchForm";
import {
  SelectedTrainProps,
  TrainInfoBatchModalHeader,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoBatchModalHeader";
import {
  SingleTrainFormSchema,
  TrickFormHookGroupedTrainFormSchema,
  groupedTrainFormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import {
  formStateHasCustomField,
  getRepresentativeTrainIds,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";
import { TrainInfoRequest } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationRequest";
import { BatchIncidentHiddenInput } from "features/CenterContent/shared/operationalInformation/components/BatchIncidentHiddenInput";
import { useOperationalInformationTypes } from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import { FC, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { TrainInfoProvider, useSetAllTrainIds } from "./useTrainInfoContext";

export type RepresentativeTrainIdentifier = {
  trainId: string;
  nominalDate: string;
};

export type RequestBodyCreateBatch = {
  type: "create";
  body: TrainInfoRequest[];
};

type TrainInfoBatchProps = {
  onSubmit: (body: RequestBodyCreateBatch) => void;
  onClose: () => void;
  submitStatus: "error" | "pending" | "idle" | "success";
  title?: string;
  submitButtonLabel?: string;
};

type SeriesData = {
  formState: SingleTrainFormSchema;
  affectedTrains: AffectedTrain[];
};

export type BatchFormState = {
  [series: string]: SeriesData;
};

/**
 * Render the train info form modal for creating new train events in batch
 * @param selectedTrains one or more trains selected in the batch
 * @param updateSelectedTrains edit trains in batch
 * @param onSubmit callback to perform when the form is submitted. The form must be valid before this callback is called.
 * @param onClose callback to perform when closing the modal
 * @param submitStatus the status of the action being performed onSubmit. Typically, the status of
 *                     the create/edit mutation
 * @param title override the title
 * @param submitButtonLabel override the label on the submit button
 */

const TrainInfoBatchModalContent: FC<
  TrainInfoBatchProps & SelectedTrainProps
> = ({
  selectedTrains,
  updateSelectedTrains,
  onSubmit,
  onClose,
  submitStatus,
  title,
  submitButtonLabel,
}) => {
  const activeTrainGrouping = useMemo<TrainSeriesGrouping>(
    () => buildTrainSeriesGroupForSingleIncident(selectedTrains)!, // Only returns null if selectedTrains is empty, which cannot happen in practice here
    [selectedTrains],
  );

  const [activeTrainSeriesIndex, setActiveTrainSeriesIndex] = useState(0);
  const trainSeriesOfActiveGroup = Object.keys(activeTrainGrouping.trainSeries);
  const activeSeriesKey = trainSeriesOfActiveGroup[activeTrainSeriesIndex];
  const allTrainSeries = activeTrainGrouping.trainSeries;
  const activeTrainSeries = allTrainSeries[activeSeriesKey];

  // Necessary for batchUseTrainRoute
  const allRepresentativeTrainIds = getRepresentativeTrainIds(allTrainSeries);
  const setAllTrainIds = useSetAllTrainIds();
  useEffect(() => {
    setAllTrainIds(allRepresentativeTrainIds);
  }, [allRepresentativeTrainIds]);

  // Zod has excellent support for dynamic keys but hook-form does not so
  // I trick it into thinking the group key is a specific weird-looking string
  const activeSeriesKeyForFormHook = activeSeriesKey as `\${groupKey}`;

  const { data: opInfoTypes, status: opInfoTypesStatus } =
    useOperationalInformationTypes();

  const { data: _infraStructureEventData } = useInfrastructureEvent([
    activeTrainGrouping.eventUuid,
  ]);
  const infrastructureEvent =
    _infraStructureEventData?.[activeTrainGrouping.eventUuid];

  // Actual type is GroupedTrainFormSchema but react-hook-form can't handle dynamic keys
  const formMethods = useForm<TrickFormHookGroupedTrainFormSchema>({
    resolver: zodResolver(groupedTrainFormSchema),
  });
  const {
    handleSubmit,
    getValues,
    reset,
    trigger,
    watch,
    formState: { errors },
  } = formMethods;

  // Populate batch state with data from infrastructure event
  useEffect(() => {
    const initialFormState = createInitialBatchFormStateFromTrainSeries(
      activeTrainGrouping.trainSeries,
      infrastructureEvent,
    );

    reset(initialFormState);
  }, [infrastructureEvent]);

  const activeFormData = watch(activeSeriesKeyForFormHook);

  const formGroupCount = Object.keys(getValues() ?? {}).length;

  const showForm =
    opInfoTypesStatus === "success" && formGroupCount && infrastructureEvent;

  // Submitting the form
  const onSubmitForm = handleSubmit((data) => {
    if (!formGroupCount) {
      log(LogLevel.error, `TrainInfoBatch`, `BatchFormState is missing`);
      throw new Error("Missing batch form state, this should not be possible.");
    }
    const requestBody = batchTrainFormToRequestBody(data);
    if (requestBody) {
      onSubmit({ type: "create", body: requestBody });
    }
  });

  return (
    <FormProvider {...formMethods}>
      <ActionModal
        title={title ?? "Opprett toghendelse"}
        actionTitle={submitButtonLabel ?? "Opprett hendelse"}
        onClose={onClose}
        onSubmit={onSubmitForm}
        onSubmitDisabled={() => {
          trigger(); // Generate detailed errors on current train series
        }}
        isSubmitDisabled={!!Object.keys(errors).length}
        onSubmitTooltip={
          Object.keys(errors).length
            ? `${Object.keys(errors).join(", ")} mangler utfylling`
            : undefined
        }
        isLoading={submitStatus === "pending"}
        isSuccess={submitStatus === "success"}
        isError={submitStatus === "error"}
        successMessage="Hendelse opprettet"
        failureMessage="Kunne ikke opprette hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer"
      >
        <ErrorBoundary>
          <Box w="100%" display="grid" gap={5}>
            {opInfoTypesStatus === "pending" && (
              <>
                <Stack gap={2}>
                  <Skeleton height={6} />
                  <Skeleton height={6} />
                </Stack>
                <SkeletonText noOfLines={3} width="30%" />
              </>
            )}

            {opInfoTypesStatus === "error" && <FailureMessage />}

            <TrainInfoBatchModalHeader
              activeTrainSeriesKey={activeSeriesKeyForFormHook}
              activeTrainSeriesIndex={activeTrainSeriesIndex}
              setActiveTrainSeriesIndex={setActiveTrainSeriesIndex}
              activeTrainSeries={activeTrainSeries}
              activeTrainGrouping={activeTrainGrouping}
              selectedTrains={selectedTrains}
              updateSelectedTrains={updateSelectedTrains}
            />

            <BatchIncidentHiddenInput
              incidentId={activeTrainGrouping.incidentId}
              pathPrefix={activeSeriesKeyForFormHook}
              numberOfGroups={
                activeFormData?.length ?? activeTrainSeries.length
              }
            />

            {showForm && (
              <>
                <TrainInfoBatchForm
                  opInfoTypes={opInfoTypes}
                  infrastructureEvent={infrastructureEvent}
                  activeTrainSeriesKey={activeSeriesKeyForFormHook}
                  groupsInSeries={activeFormData.length}
                />

                {activeFormData.some((data) =>
                  formStateHasCustomField(data.trainForm),
                ) && (
                  <DropsStaticAlert variant="info" id="infoBoxActionModal">
                    Hendelsen inneholder egendefinert tekst. Sjekk at
                    oppsummeringen ser riktig ut før du går videre.
                  </DropsStaticAlert>
                )}
              </>
            )}

            {!showForm && <SkeletonLoader skeletonType="affectedTrains" />}
          </Box>
        </ErrorBoundary>
      </ActionModal>
    </FormProvider>
  );
};

const TrainInfoBatchModal: FC<TrainInfoBatchProps & SelectedTrainProps> = (
  props,
) => (
  <TrainInfoProvider>
    <TrainInfoBatchModalContent {...props} />
  </TrainInfoProvider>
);

export default TrainInfoBatchModal;
