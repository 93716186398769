import {
  Box,
  Button,
  Flex,
  VStack,
  Text,
  ChoiceChip,
  SearchInput,
} from "@vygruppen/spor-react";

import { AddOutline24Icon } from "@vygruppen/spor-icon-react";
import { useQuery } from "@tanstack/react-query";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { getBackendUrl } from "api/common";
import { useEffect, useState } from "react";
import ChecklistTable from "./ChecklistTable";
import { ChecklistFillOuts, FillOut } from "./FillOut";
import {
  ChecklistHistoryWrapper,
  NoLabelOverflow,
  NoDivOverflowButton,
  ChangeInternalFontSize,
} from "./ChecklistCustomStyle";
import ChecklistModal from "./ChecklistModal";

const fillOutTypes = ["Bremseprøve", "Innsett (IKS)", "Uttak (UKS)"];

const Checklist = () => {
  const [filter, setFilter] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [showAll, setShowAll] = useState(false);

  const {
    data: checklistFillOuts,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["checklist", searchQuery],
    queryFn: ({ signal }) =>
      searchQuery.length > 1
        ? queryFnGET<ChecklistFillOuts>({
            signal,
            url: `${getBackendUrl()}/checklist/fill-outs/?rollingStockId=${searchQuery}`,
          })
        : Promise.resolve({ fillOuts: [] }),
    staleTime: 10,
  });

  const fillOuts = checklistFillOuts ? checklistFillOuts.fillOuts : [];
  const displayedFillOuts = fillOuts
    .filter(
      (fillOut: FillOut) =>
        filter.length === 0 || filter.includes(fillOut.checklistType),
    )
    .slice(0, showAll ? undefined : 8);

  const handleFilterToggle = (type: string) => {
    setFilter((prev) =>
      prev.includes(type)
        ? prev.filter((item) => item !== type)
        : [...prev, type],
    );
  };

  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    refetch();
  }, [searchQuery, filter, refetch]);

  return (
    <ChecklistHistoryWrapper>
      <VStack>
        <Box maxWidth="1600px" width="100%">
          <Flex
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            mt="4"
          >
            <Flex alignItems="center" pl="1" w="100%" maxW="1200px">
              <Flex alignItems="center" flex={1} pr="1">
                <NoLabelOverflow>
                  <SearchInput
                    autoFocus
                    type="search"
                    label="Søk etter kjøretøy-id"
                    value={searchQuery}
                    onChange={(e) =>
                      setSearchQuery(e.target.value.toUpperCase())
                    }
                    maxWidth="600px"
                  />
                </NoLabelOverflow>
                <Flex gap={2} alignItems="center">
                  <Text pl="2">Vis</Text>
                  {fillOutTypes.map((type) => (
                    <ChangeInternalFontSize key={type}>
                      <ChoiceChip
                        size="sm"
                        variant="base"
                        isChecked={filter.includes(type)}
                        onChange={() => handleFilterToggle(type)}
                      >
                        {type}
                      </ChoiceChip>
                    </ChangeInternalFontSize>
                  ))}
                </Flex>
              </Flex>
            </Flex>
            <NoDivOverflowButton>
              <Button
                variant="secondary"
                onClick={() => setModalOpen(true)}
                leftIcon={<AddOutline24Icon />}
                size="md"
              >
                Opprett ny kvittering
              </Button>
            </NoDivOverflowButton>
          </Flex>
          <ChecklistTable
            fillouts={displayedFillOuts}
            totalFillOuts={fillOuts.length}
            isLoading={isLoading}
            refetch={refetch}
          />
          {fillOuts.length > 0 && (
            <Flex justifyContent="end" alignItems="center" pt="10px" pb="24px">
              <Text pr={2}>
                Viser {displayedFillOuts.length} av {fillOuts.length}{" "}
                kvitteringer
              </Text>
              <Button variant="tertiary" onClick={() => setShowAll(!showAll)}>
                {showAll ? "Vis færre" : "Vis alle"}
              </Button>
            </Flex>
          )}
        </Box>
        {modalOpen && (
          <ChecklistModal
            isOpen={modalOpen}
            onClose={handleModalClose}
            currentFillOut={null}
            refetch={refetch}
          />
        )}
      </VStack>
    </ChecklistHistoryWrapper>
  );
};

export default Checklist;
