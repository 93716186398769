import { FC, useState, JSX } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Button,
  Divider,
  Flex,
  Text,
} from "@vygruppen/spor-react";
import { WagonFill18Icon } from "@vygruppen/spor-icon-react";
import { useTheme } from "styled-components";
import { FillOut } from "./FillOut";
import { DetailsContainer } from "./ChecklistCustomStyle";

interface ConfirmChecklistModelProps {
  isOpen: boolean;
  onClose: () => void;
  currentFillOut: FillOut | null;
  handleDelete: (checklist: FillOut) => void;
  type: string;
  refetch: () => void;
}

function dateTime(val: string) {
  const [date, timeWithZ] = val.split("T");
  const time = timeWithZ.split(".")[0].replace("Z", "");
  return `${date} kl ${time}`;
}

const wait = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const ChecklistDetail: FC<{ label: string; value: string | JSX.Element }> = ({
  label,
  value,
}) => (
  <Flex pl="18px" pt="10px" gap="10px">
    <Text fontWeight="900">{label}: </Text>
    <Text>{value}</Text>
  </Flex>
);

const ConfirmChecklistModal: FC<ConfirmChecklistModelProps> = ({
  isOpen,
  onClose,
  currentFillOut,
  handleDelete,
  type,
  refetch,
}) => {
  const theme = useTheme();
  const [isDeleted, setIsDeleted] = useState(false);

  const handleDeleteClick = async () => {
    if (currentFillOut) {
      try {
        await handleDelete(currentFillOut);
        setIsDeleted(true);
        await wait(2000);
        setIsDeleted(false);
        refetch();
        onClose();
      } catch (error) {
        console.error("Error deleting checklist:", error);
      }
    }
  };

  const renderContent = () => {
    if (isDeleted) {
      return (
        <Text fontWeight={500} pl={30} pt={30} pb={30}>
          Kvitteringen er slettet.
        </Text>
      );
    }

    return (
      <>
        <Text fontWeight={500} pl={30} pt={30} pb={30}>
          {type === "delete"
            ? "Du er i ferd med å slette denne kvitteringen:"
            : "Du er i ferd med å kvittere denne kvitteringen:"}
        </Text>
        <DetailsContainer
          $borderColor={
            type === "delete" ? theme.colorAlarmOutline : theme.colorSuccess
          }
        >
          <ChecklistDetail
            label="Opprettet av"
            value={currentFillOut?.executor || "N/A"}
          />
          <ChecklistDetail
            label="Tidspunkt"
            value={currentFillOut ? dateTime(currentFillOut.executedAt) : "N/A"}
          />
          <ChecklistDetail
            label="Kvitteringsliste"
            value={currentFillOut?.checklistType || "N/A"}
          />
          <Flex pl="10px" gap="10px">
            <Text fontWeight="900">Checklistetøy ID: </Text>
            {currentFillOut && (
              <Flex key={currentFillOut.rollingStock} alignItems="center">
                <WagonFill18Icon />
                <Text>{currentFillOut.rollingStock}</Text>
              </Flex>
            )}
          </Flex>
        </DetailsContainer>
        {type === "delete" && (
          <Text fontWeight={500} pl={30} pt={30} pb={30}>
            Er du sikker på at du vil slette?
          </Text>
        )}
        <Divider />
        <ModalFooter pt={2}>
          <Flex justifyContent="space-between" width="100%">
            <Button pl={0} pr={100} onClick={onClose} variant="tertiary">
              Avbryt
            </Button>
            {type === "delete" && (
              <Button
                variant="primary"
                onClick={handleDeleteClick}
                sx={{
                  backgroundColor: theme.colorAlarm,
                  "&:hover": {
                    backgroundColor: "darkred",
                  },
                }}
              >
                Ja, slett kvittering
              </Button>
            )}
          </Flex>
        </ModalFooter>
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} aria-labelledby="modal-title">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader id="modal-title">
          {type === "delete"
            ? "Slett kvittering"
            : `Kvitter for ${currentFillOut?.vehicleType}`}
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        {renderContent()}
      </ModalContent>
    </Modal>
  );
};

export default ConfirmChecklistModal;
