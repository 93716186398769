import { useState } from "react";
import { getTodoRole, UserRole } from "shared/types/roles";

export type TodoFilterStatus = "incomplete" | "completed" | "futureAlarms";

export type TodoFilterState = {
  roles: UserRole[];
  vehicleSetTypes: string[];
  trainNumber: string;
  todoStatus: TodoFilterStatus[];
};

export const defaultTodoFilterState: TodoFilterState = {
  roles: [],
  vehicleSetTypes: [],
  trainNumber: "",
  todoStatus: ["incomplete"],
};

export function isDefault(
  todoFilterState: TodoFilterState | null,
  currentRole: UserRole,
): boolean {
  return (
    JSON.stringify(todoFilterState) ===
    JSON.stringify({
      ...defaultTodoFilterState,
      roles: getTodoRole(currentRole),
    })
  );
}

// Uncomment "Vis åpne" if the users want to filter out opened messages as well.
// It is currently hidden to reduce complexity for users.
export const FILTER_STATUS_LIST: { text: string; value: TodoFilterStatus }[] = [
  // { text: "Vis åpne", value: "incomplete" },
  { text: "Vis fullførte", value: "completed" },
];

export const VEHICLE_SET_TYPES = [
  { text: "69", value: "69" },
  { text: "70", value: "70" },
  { text: "72", value: "72" },
  { text: "73", value: "73" },
  { text: "74", value: "74" },
  { text: "75", value: "75" },
  { text: "Lok og vogn", value: "lokvogn" },
];

// add if not in list, remove if in list
function addOrRemove<T>(list: T[], item: T): T[] {
  return list.includes(item) ? list.filter((i) => i !== item) : [...list, item];
}

const useTodoFilterState = (initialTodoFilterState?: TodoFilterState) => {
  const [todoFilterState, setTodoFilterState] = useState<TodoFilterState>(
    initialTodoFilterState ?? defaultTodoFilterState,
  );

  // TODO Fjernes når alle er over på nye roller
  const getDeprecatedExtraRole = (role: UserRole) => {
    if (role === UserRole.lokleder1 || role === UserRole.lokleder2) {
      return UserRole.lokleder;
    }
    if (
      role === UserRole.materiellstyrerlokal ||
      role === UserRole.materiellstyrerregion
    ) {
      return UserRole.materiellstyrer;
    }
    return null;
  };

  // TODO Fjernes når alle er over på nye roller
  const addOrRemoveMultiple = (prev: UserRole[], role: UserRole) => {
    const updatedList = addOrRemove(prev, role);
    const deprecatedRole = getDeprecatedExtraRole(role);
    switch (deprecatedRole) {
      case UserRole.lokleder:
        return updatedList.includes(UserRole.lokleder1) ||
          updatedList.includes(UserRole.lokleder2)
          ? [...updatedList, UserRole.lokleder]
          : updatedList.filter((i) => i !== UserRole.lokleder);
      case UserRole.materiellstyrer:
        return updatedList.includes(UserRole.materiellstyrerlokal) ||
          updatedList.includes(UserRole.materiellstyrerregion)
          ? [...updatedList, UserRole.materiellstyrer]
          : updatedList.filter((i) => i !== UserRole.materiellstyrer);
      default:
        return updatedList;
    }
  };

  const toggleRoles = (role: UserRole) => {
    setTodoFilterState((prevState) => ({
      ...prevState,
      roles: addOrRemoveMultiple(prevState.roles, role),
    }));
  };

  const toggleVehicleSetTypes = (vehicleSetType: string) => {
    setTodoFilterState((prevState) => ({
      ...prevState,
      vehicleSetTypes: addOrRemove(prevState.vehicleSetTypes, vehicleSetType),
    }));
  };

  const setTrainNumber = (trainNumber: string) => {
    setTodoFilterState((prevState) => ({
      ...prevState,
      trainNumber,
    }));
  };

  const toggleTodoStatus = (status: TodoFilterStatus) => {
    setTodoFilterState((prevState) => ({
      ...prevState,
      todoStatus: addOrRemove(prevState.todoStatus, status),
    }));
  };

  const clearTodoFilterState = () => {
    setTodoFilterState(defaultTodoFilterState);
  };

  return {
    todoFilterState,
    setTodoFilterState,
    clearTodoFilterState,
    toggleRoles,
    toggleVehicleSetTypes,
    setTrainNumber,
    toggleTodoStatus,
  };
};

export default useTodoFilterState;
