import { Button } from "@vygruppen/spor-react";

import { ArrowLeftOutline24Icon } from "@vygruppen/spor-icon-react";
import { useTheme } from "styled-components";
import { Tab } from "./TabsWindows";

export const CombineButton = ({
  tabs,
  setSelectedTabIds,
}: {
  tabs: Tab[];
  setSelectedTabIds: (tabIds: string[]) => void;
}) => {
  const theme = useTheme();
  return (
    <Button
      size="sm"
      variant="ghost"
      backgroundColor={theme.colorBackgroundSecondary}
      onClick={() => {
        setSelectedTabIds([tabs[0].id]);
      }}
      leftIcon={<ArrowLeftOutline24Icon />}
    >
      Lukk
    </Button>
  );
};
