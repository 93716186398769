import { Flex, Text } from "@vygruppen/spor-react";
import { MessageList } from "features/CenterContent/RoleContent/InternalMessage/shared/MessageList";
import { TextareaExpandable } from "features/CenterContent/VehicleDetails/shared/TextareaExpandable";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { ChevronUpButton } from "shared/components/buttons/ChevronButton";
import { PlusButton } from "shared/components/buttons/PlusButton";
import { NoteOutline24Icon } from "shared/icons/NoteOutline24Icon";
import { EndsWith } from "shared/utils/objectUtils";
import styled from "styled-components";

const InternalMessageContainer = styled.div`
  /* Positioning */
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;

  /* Styling */
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  border: 1px solid ${({ theme }) => theme.colorOutline};
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;

  /* Spacing */
  padding: 18px 24px 18px 24px;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

type InternalMessageProps = {
  formField: EndsWith<"internalMessages">;
  isEditMode: boolean;
};

const InternalMessageContent = ({
  formField,
  isEditMode,
}: InternalMessageProps) => {
  const { register, watch } = useFormContext();
  const [active, setActive] = useState(false);
  const messageTextField = `${formField}[0].text`;

  const description = watch(formField);
  if (description === undefined) {
    console.log(
      `FormField (${formField}) does not have a defaultValue. This component is most likely set up wrong.\n
       Use internalMessagesFormDefault() or internalMessagesResponseToForm() as starting value.`,
    );
    return null;
  }

  return (
    <Flex height="80px" position="relative">
      <InternalMessageContainer>
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          <Flex gap={1}>
            <NoteOutline24Icon />
            <Text fontWeight="900">Intern melding</Text>
          </Flex>
          {isEditMode ? (
            <ChevronUpButton active={active} setActive={setActive} />
          ) : (
            <PlusButton active={active} setActive={setActive} />
          )}
        </Flex>

        {isEditMode ? (
          <MessageList
            formField={formField}
            collapsed={!active}
            maxHeight="205px"
          />
        ) : (
          <TextareaExpandable
            {...register(messageTextField)}
            mt={active ? "12px" : "0px"}
            $collapsed={!active}
          />
        )}
      </InternalMessageContainer>
    </Flex>
  );
};

export const InternalMessage = (props: InternalMessageProps) => {
  if (!useFormContext()) {
    console.log(
      `No form-context was found. This component is most likely set up wrong.`,
    );
    return null;
  }
  return <InternalMessageContent {...props} />;
};
