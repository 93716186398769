import { Text, Flex, TextLink, Stack } from "@vygruppen/spor-react";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { FC } from "react";
import { TextAreaWithCharacterCount } from "shared/components/forms/TextAreaWithCharacterCount";
import { GlobalFormSchema } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { UseFormReturn } from "react-hook-form";
import styled from "styled-components";
import chainLink from "shared/icons/chain-link.svg?react";

export const ChainLinkIcon = styled(chainLink)`
  width: 18px;
  path {
    fill: ${({ theme }) => theme.colorTextMain};
  }
`;

type ModalContentProps = {
  formMethods: UseFormReturn<GlobalFormSchema>;
  isDisabled?: boolean;
};

export const GlobalEventModalContent: FC<ModalContentProps> = ({
  formMethods,
  isDisabled,
}) => {
  const textFieldErrors =
    formMethods.formState.errors.globalForm?.customTexts?.texts;
  return (
    <ErrorBoundary>
      <Flex gap={3} flexDirection="column" width="100%">
        <Stack gap={1}>
          <Text variant="xs">Skriv egendefinert melding</Text>
          <Text variant="xs">
            Hjelpetekster til ulike situasjoner finnes{" "}
            <TextLink href="https://vygruppen.atlassian.net/wiki/spaces/KUNDEINFO/pages/7180877920/Ekstra+kundeinformasjon+ved+store+oppst+tte+avvik+og+hendelser">
              her
            </TextLink>
          </Text>
        </Stack>
        <TextAreaWithCharacterCount
          label="Norsk overskrift *"
          formMethods={formMethods}
          maxLength={60}
          formField="NOB.title"
          isInvalid={!!textFieldErrors?.NOB?.title}
          errorMessage={textFieldErrors?.NOB?.title?.message}
          isDisabled={isDisabled}
        />
        <TextAreaWithCharacterCount
          label="Norsk tekst *"
          formMethods={formMethods}
          maxLength={425}
          minHeight="150px"
          formField="NOB.description"
          isInvalid={!!textFieldErrors?.NOB?.description}
          errorMessage={textFieldErrors?.NOB?.description?.message}
          isDisabled={isDisabled}
        />
        <TextAreaWithCharacterCount
          label="English title"
          formMethods={formMethods}
          maxLength={60}
          formField="ENG.title"
          isInvalid={!!textFieldErrors?.ENG?.title}
          errorMessage={textFieldErrors?.ENG?.title?.message}
          isDisabled={isDisabled}
        />
        <TextAreaWithCharacterCount
          label="English text"
          formMethods={formMethods}
          maxLength={425}
          minHeight="150px"
          formField="ENG.description"
          isInvalid={!!textFieldErrors?.ENG?.description}
          errorMessage={textFieldErrors?.ENG?.description?.message}
          isDisabled={isDisabled}
        />

        {/* <Input leftIcon={<ChainLinkIcon />} label="Legg til lenke" /> */}
      </Flex>
    </ErrorBoundary>
  );
};
