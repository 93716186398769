/**
 * Context:
 * Internal message is a type of global event. The reason this is seperated into its own folder is
 * because interal message is used in a very different way than other global events.
 * Therefore, seperating its usage makes it easier for overview.
 */

import { CountryCode } from "@vygruppen/vy-train-map";
import {
  CustomTexts,
  VisibilityRequest,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { OperationalIdentifier_JSON } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { Stop } from "features/CenterContent/VehicleDetails/TrainDetails/useExistingTrainCancelledEvents";
import { OriginSystem } from "features/History/HistoryDetail/fetchHistoryDetail";
import { z } from "zod";

type InternalMessageCommon = {
  visibility: VisibilityRequest;
  customTexts: CustomTexts[];

  relatedEventUuid?: string;
  relatedEventUuidCategory?: string;
  relevantTrain?: OperationalIdentifier_JSON; // Backend requires snakeCase
};

export type InternalMessageRequest = {
  globalInformationType: "GLOBAL_INTERNAL_INFO";
  incidentIds: string[];
  countryCode: CountryCode;

  uuid?: string;
  relevantStops: string[];
} & InternalMessageCommon;

export type InternalMessageResponse = {
  type: "GLOBAL_INTERNAL_INFO";
  uuid: string;
  updatedAt: string;
  referencedIncidentIds: string[];
  originSystem: OriginSystem;

  relevantStops: Stop[];
} & InternalMessageCommon;

const internalMessageSchema = z.object({
  uuid: z.string().optional(),
  text: z.string(),
  author: z.string().optional(),
  updatedAt: z.string(),
});

export const internalMessagesSchema = internalMessageSchema.array().nullish();

export type InternalMessagesForm = z.infer<typeof internalMessagesSchema>;
