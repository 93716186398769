import { TrainOutline18Icon } from "@vygruppen/spor-icon-react";
import { Flex } from "@vygruppen/spor-react";
import {
  getLocalStorageVehicleAffectedSidePanelSettings,
  setLocalStorageVehicleAffectedSidePanelSettings,
} from "api/dropsLocalStorage";
import AffectedTrains from "features/CenterContent/RoleContent/AffectedTrains/AffectedTrains";
import { useAffectedTrains } from "features/CenterContent/RoleContent/AffectedTrains/useAffectedTrains";
import CollapseButton from "features/CenterContent/shared/Tabs/CollapseButton";
import {
  Tab,
  TabsWindows,
} from "features/CenterContent/shared/Tabs/TabsWindows";
import { CloseButtonWrapper } from "features/TodoOplogg/TodoOplogg";
import VehicleList from "features/VehicleList/VehicleList";
import { useEffect, useState } from "react";
import { InfrastructureIncidentIcon } from "shared/icons/InfrastructureIncidentIcon";
import { dropsRole } from "stores/dropsRole";
import { useSidePanelsState } from "stores/useSidePanelsState";
import { VehicleAffectedContainer } from "./styles";

export type VehicleAffectedContainerProps = { isOpen: boolean };

const VehicleAffected = () => {
  const [isOpen, setIsOpen] = useSidePanelsState((state) => [
    state.trainListOpen,
    state.setTrainListOpen,
  ]);

  useEffect(() => {
    setIsOpen(getLocalStorageVehicleAffectedSidePanelSettings());
  }, []);

  useEffect(() => {
    setLocalStorageVehicleAffectedSidePanelSettings(isOpen);
  }, [isOpen]);

  const affectedTrainsResult = useAffectedTrains();
  const numberOfAffectedTrains =
    affectedTrainsResult.data?.affectedTrains.length ?? undefined;

  const tabs: Tab[] = [
    {
      id: "trainList",
      icon: <TrainOutline18Icon />,
      text: "Togliste",
    },
    {
      id: "affectedTrains",
      icon: <InfrastructureIncidentIcon />,
      text: `Arbeidsliste`,
    },
  ];

  const [selectedTabIds, setSelectedTabIds] = useState(["trainList"]);

  const { isVaktleder } = dropsRole();
  const affectedTrainsEnabled = isVaktleder();
  // If you change the role to one without affectedTrains access, it switches to trainList
  useEffect(() => {
    if (!affectedTrainsEnabled) {
      setSelectedTabIds(["trainList"]);
    }
  }, [affectedTrainsEnabled]);

  return (
    <VehicleAffectedContainer isOpen={isOpen}>
      {affectedTrainsEnabled && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          flexDir="row-reverse"
        >
          <TabsWindows
            tabs={tabs}
            selectedTabIds={selectedTabIds}
            setSelectedTabIds={setSelectedTabIds}
            justifyContent="start"
            margin="12px"
            notifications={[0, numberOfAffectedTrains ?? 0]}
          />
          <CloseButtonWrapper style={{ position: "absolute" }}>
            <CollapseButton
              isOpen={!isOpen}
              setOpen={() => setIsOpen(!isOpen)}
              title="Skjul togliste"
            />
          </CloseButtonWrapper>
        </Flex>
      )}
      {!affectedTrainsEnabled && (
        <CloseButtonWrapper style={{ position: "absolute" }}>
          <CollapseButton
            isOpen={!isOpen}
            setOpen={() => setIsOpen(!isOpen)}
            title="Skjul togliste"
          />
        </CloseButtonWrapper>
      )}
      <Flex overflowY="auto" width="100%">
        {selectedTabIds.includes("trainList") && <VehicleList />}
        {selectedTabIds.includes("affectedTrains") && (
          <AffectedTrains affectedTrainsResult={affectedTrainsResult} />
        )}
      </Flex>
    </VehicleAffectedContainer>
  );
};

export default VehicleAffected;
