import { useQuery } from "@tanstack/react-query";
import {
  CalendarOutline24Icon,
  WarningFill18Icon,
  WarningOutline18Icon,
} from "@vygruppen/spor-icon-react";
import { Flex } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import { InternalMessageResponse } from "features/CenterContent/RoleContent/InternalMessage/types";
import { EventsOverviewModal } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewModal";
import { useGlobalInformation } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/useGlobalInformation";
import { useState } from "react";
import { SemanticButton } from "shared/components/buttons/SemanticButton";
import { AlertsCounter } from "shared/components/dataDisplay/AlertsCounter";
import {
  InfraStructureInformation,
  upcomingEventsResponseSchema,
} from "shared/types/infrastructureResponse";
import { GlobalEventType } from "shared/types/operationalInformation";
import { dropsRole } from "stores/dropsRole";
import { useTheme } from "styled-components";

export const UPCOMING_EVENTS_QUERY_KEY = "upcomingInfrastructureEvents";
export const INTERNAL_EVENTS_QUERY_KEY = "internalEvents";

export const EventsOverviewButtonAndModal = () => {
  const [showModal, setShowModal] = useState(false);
  const { enabled: globalEventsEnabled } = useFeatureFlag("gobalEvents");
  const { enabled: internalEventsEnabled } = useFeatureFlag("internalMessage");
  const { isVaktleder } = dropsRole();

  const upcomingInfrastructureEvents = useQuery({
    queryKey: [UPCOMING_EVENTS_QUERY_KEY],
    staleTime: 0,
    queryFn: ({ signal }) =>
      axiosClient
        .get<InfraStructureInformation[]>(
          `${getBackendUrl()}/infrastructure-event/upcoming?countryCode=NO`,
          {
            signal,
          },
        )
        .then((res) => upcomingEventsResponseSchema.parse(res.data)),
  });

  const { globalEvents } = useGlobalInformation();

  const internalEventsType: GlobalEventType = "GLOBAL_INTERNAL_INFO";

  const internalMessageEvents = useQuery({
    queryKey: [INTERNAL_EVENTS_QUERY_KEY],
    staleTime: 0,
    queryFn: ({ signal }) =>
      axiosClient
        .get<InternalMessageResponse[]>(
          `${getBackendUrl()}/global-information/visible/NO/${internalEventsType}`,
          {
            signal,
          },
        )
        .then((res) => res.data.reverse()),
  });

  const numOfGlobalEvents =
    globalEvents.isSuccess && globalEventsEnabled
      ? globalEvents.data.length
      : 0;

  const numOfInternalEvents =
    internalMessageEvents.isSuccess && internalEventsEnabled
      ? internalMessageEvents.data.length
      : 0;

  const numberOfEvents = upcomingInfrastructureEvents.isSuccess
    ? upcomingInfrastructureEvents.data.length +
      numOfGlobalEvents +
      numOfInternalEvents
    : 0;

  const isActiveGlobalEvents =
    globalEvents.isSuccess && globalEvents.data.length > 0;

  const theme = useTheme();

  const severity =
    isActiveGlobalEvents && globalEventsEnabled && isVaktleder()
      ? "warning"
      : undefined; // No severity

  const Warning18Icon =
    severity === "warning" ? WarningFill18Icon : WarningOutline18Icon;

  return (
    <>
      <SemanticButton
        severity={severity}
        variant="tertiary"
        size="md"
        leftIcon={
          globalEventsEnabled && isVaktleder() ? (
            <Warning18Icon />
          ) : (
            <CalendarOutline24Icon />
          )
        }
        aria-label="Se fremtidige hendelser"
        onClick={() => setShowModal(true)}
        style={{ width: "fit-content" }}
      >
        {globalEventsEnabled && isVaktleder() ? (
          <Flex
            gap="6px"
            alignItems="center"
            color={
              severity === "warning"
                ? theme.colorWarningTextSecondary
                : theme.colorNeutralTextPrimary
            }
          >
            Oversikt hendelser
            <AlertsCounter
              alerts={numberOfEvents}
              severity={severity ?? "neutral"}
            />
          </Flex>
        ) : (
          "Fremtidige hendelser"
        )}
      </SemanticButton>

      {showModal && (
        <EventsOverviewModal
          onClose={() => setShowModal(false)}
          upcomingInfrastructureEvents={upcomingInfrastructureEvents}
          activeGlobalEvents={globalEvents}
          internalMessageEvents={internalMessageEvents}
        />
      )}
    </>
  );
};
