import { FC, useState } from "react";
import {
  Text,
  Button,
  Accordion,
  AccordionIcon,
  Flex,
  Grid,
  ContentLoader,
} from "@vygruppen/spor-react";
import { useTheme } from "styled-components";
import { DashedLine } from "features/CenterContent/VehicleDetails/shared/DashedLine";
import { format, parseISO } from "date-fns";
import {
  EditOutline24Icon,
  WagonFill30Icon,
  DeleteOutline30Icon,
  KlippekortOutline30Icon,
  LinkOutOutline30Icon,
} from "@vygruppen/spor-icon-react";
import { mutationFnDELETE } from "api/tanStackQuery/helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { DropsBadge } from "shared/components/badge/DropsBadge";
import { FillOut } from "./FillOut";
import {
  ChecklistCustomTableStyle,
  AccordionButtonStyled,
  AccordionPanelStyled,
  AccordionItemStyled,
  GridStyled,
  EmptyTableText,
  LinkOutButton,
  LinkOutHideTextWhenNarrow,
} from "./ChecklistCustomStyle";
import ChecklistModal from "./ChecklistModal";
import ConfirmChecklistModal from "./ConfirmChecklistModel";

interface ChecklistTableProps {
  fillouts: FillOut[];
  totalFillOuts: number;
  isLoading: boolean;
  refetch: () => void;
}

// Format time to just hours and minutes, adjusted to local time
function formatTime(dateTime: string) {
  const date = parseISO(dateTime);
  return format(date, "HH:mm");
}

// Format date to dd.MM.yyyy
function formatDate(dateTime: string) {
  const date = parseISO(dateTime);
  return format(date, "dd.MM.yyyy");
}

const ChecklistTable: FC<ChecklistTableProps> = ({
  fillouts,
  totalFillOuts,
  isLoading,
  refetch,
}) => {
  const theme = useTheme();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentFillOut, setCurrentFillOuts] = useState<FillOut | null>(null);
  const [confirmModal, setConfirmModal] = useState<string>("");

  const queryClient = useQueryClient();

  const { mutate: deleteChecklist } = useMutation({
    mutationFn: (id: string) =>
      mutationFnDELETE<undefined>(
        `${getBackendUrl()}/checklist/fill-out/${id}`,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["checklist"] });
    },
  });

  const handleDelete = (checklistToDelete: FillOut) => {
    deleteChecklist(checklistToDelete.fillOutId, {
      onSuccess: () => {
        setConfirmModal("");
      },
    });
  };

  const handleEditClick = (checklist: FillOut) => {
    setCurrentFillOuts(checklist);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCurrentFillOuts(null);
  };

  return (
    <>
      <ChecklistCustomTableStyle variant="simple" size="lg">
        <GridStyled>
          <Text>Dato</Text>
          <Text>Endret</Text>
          <Text>Kvittering</Text>
          <Text>Kjøretøy-ID</Text>
          <Text>Opprettet av</Text>
          <Text>Endret av</Text>
          <Text textAlign="end" fontWeight="500">
            <span style={{ fontWeight: "bold" }}>{totalFillOuts}</span> Treff
          </Text>
        </GridStyled>
        {isLoading && <ContentLoader pt="20%" />}
        {!isLoading && fillouts.length === 0 && (
          <EmptyTableText>
            <KlippekortOutline30Icon mb="20px" />
            <Text variant="md" width="400px" textAlign="center">
              Søk på kjøretøy-ID eller tognummer for å se tilhørende
              kvitteringer
            </Text>
          </EmptyTableText>
        )}
        {!isLoading &&
          fillouts.map((checklist, index) => (
            <Accordion allowToggle key={index}>
              <AccordionItemStyled key={index}>
                <AccordionButtonStyled>
                  <div>
                    <Text>{formatDate(checklist.executedAt)}</Text>
                    <Text fontWeight={500} color={theme.colorTextSecondary}>
                      {formatTime(checklist.executedAt)}
                    </Text>
                  </div>
                  <div>
                    <Text>{formatDate(checklist.changedAt)}</Text>
                    <Text fontWeight={500} color={theme.colorTextSecondary}>
                      {formatTime(checklist.changedAt)}
                    </Text>
                  </div>
                  <LinkOutHideTextWhenNarrow>
                    <LinkOutButton
                      variant="tertiary"
                      size="xs"
                      maxWidth="180px"
                      onClick={() => {
                        window.open(
                          "https://nsbas.sharepoint.com/sites/Sjekklisterforkjrety/Lists/Sjekklister/AllItems.aspx",
                          "blank",
                        );
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <Text variant="xs" fontWeight={500}>
                          {checklist.checklistType}
                        </Text>
                        <LinkOutOutline30Icon />
                      </div>
                    </LinkOutButton>
                  </LinkOutHideTextWhenNarrow>
                  <div
                    style={{
                      display: "flex",
                      paddingLeft: "7px",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <WagonFill30Icon />
                    <Text>
                      {checklist.rollingStock ? checklist.rollingStock : null}
                    </Text>
                  </div>

                  <Text fontWeight={500}>{checklist.executor ?? "N/A"}</Text>
                  <Text fontWeight={500} pl="10px">
                    {checklist.changedBy === "" ? (
                      <DropsBadge semantics="alarm">Uendret</DropsBadge>
                    ) : (
                      checklist.changedBy
                    )}
                  </Text>

                  <AccordionIcon marginX="auto" />
                </AccordionButtonStyled>

                <AccordionPanelStyled overflow="visible">
                  <DashedLine
                    $color={theme.colorOutline}
                    mt="-14px"
                    h="2px"
                    w="80%"
                    $strokeWidth="5px"
                    $spacing="5px"
                  />

                  {checklist.history ? (
                    Object.entries(checklist.history).map(
                      ([version, change], i, array) => {
                        const previousRollingStock =
                          i > 0 ? array[i - 1][1].rollingStock : null;
                        const previousFillOutType =
                          i > 0 ? array[i - 1][1].checklistType : null;
                        const previousCompany_id =
                          i > 0 ? array[i - 1][1].companyId : null;
                        const previousVehicleType =
                          i > 0 ? array[i - 1][1].vehicleType : null;
                        return (
                          <Grid
                            key={version}
                            templateColumns="repeat(6, 1fr) 55px"
                            marginBottom="20px"
                          >
                            <Text fontWeight="900" gridColumn="2">
                              {change.changedAt.split("T")[0]}
                            </Text>
                            <Text gridColumn="2">
                              {formatTime(change.changedAt)}
                            </Text>
                            {previousRollingStock !== change.rollingStock &&
                              previousRollingStock && (
                                <>
                                  <Text fontWeight="900" gridColumn="2">
                                    Endret:
                                  </Text>
                                  <Text gridColumn="3 / 7">
                                    Kjøretøys-ID fra{" "}
                                    <Text as="span" fontWeight="bold">
                                      {previousRollingStock}
                                    </Text>{" "}
                                    til{" "}
                                    <Text as="span" fontWeight="bold">
                                      {change.rollingStock}
                                    </Text>
                                  </Text>
                                </>
                              )}
                            {previousFillOutType !== change.checklistType &&
                              previousFillOutType && (
                                <>
                                  <Text fontWeight="900" gridColumn="2">
                                    Endret:
                                  </Text>
                                  <Text gridColumn="3 / 7">
                                    Kvitteringstype fra{" "}
                                    <Text as="span" fontWeight="bold">
                                      {previousFillOutType}
                                    </Text>{" "}
                                    til{" "}
                                    <Text as="span" fontWeight="bold">
                                      {change.checklistType}
                                    </Text>
                                  </Text>
                                </>
                              )}
                            {previousCompany_id !== change.companyId &&
                              previousCompany_id && (
                                <>
                                  <Text fontWeight="900" gridColumn="2">
                                    Endret:
                                  </Text>
                                  <Text gridColumn="3 / 7">
                                    Tog eier fra{" "}
                                    <Text as="span" fontWeight="bold">
                                      {previousCompany_id}
                                    </Text>{" "}
                                    til{" "}
                                    <Text as="span" fontWeight="bold">
                                      {change.companyId}
                                    </Text>
                                  </Text>
                                </>
                              )}
                            {previousCompany_id !== change.companyId &&
                              previousCompany_id && (
                                <>
                                  <Text fontWeight="900" gridColumn="2">
                                    Endret:
                                  </Text>
                                  <Text gridColumn="3 / 7">
                                    Tog type fra{" "}
                                    <Text as="span" fontWeight="bold">
                                      {previousVehicleType}
                                    </Text>{" "}
                                    til{" "}
                                    <Text as="span" fontWeight="bold">
                                      {change.vehicleType}
                                    </Text>
                                  </Text>
                                </>
                              )}
                            <Text fontWeight="900" gridColumn="2">
                              Endret av:
                            </Text>
                            <Text gridColumn="3 / 7">{change.changedBy}</Text>
                            <Text fontWeight="900" gridColumn="2">
                              Kommentar:
                            </Text>
                            <Text gridColumn="3 / 7">
                              {change.comment || "Ingen kommentar"}
                            </Text>
                          </Grid>
                        );
                      },
                    )
                  ) : (
                    <Text>Ingen historikk tilgjengelig</Text>
                  )}

                  <Flex justifyContent="flex-end">
                    <Button
                      aria-label="Slett kvittering"
                      variant="ghost"
                      onClick={() => {
                        setCurrentFillOuts(checklist);
                        setConfirmModal("delete");
                      }}
                      leftIcon={<DeleteOutline30Icon />}
                    >
                      Slett kvittering
                    </Button>

                    <Button
                      aria-label="Rediger kvittering"
                      variant="tertiary"
                      rightIcon={<EditOutline24Icon />}
                      onClick={() => handleEditClick(checklist)}
                    >
                      Endre
                    </Button>
                  </Flex>
                </AccordionPanelStyled>
              </AccordionItemStyled>
            </Accordion>
          ))}
      </ChecklistCustomTableStyle>

      {currentFillOut && (
        <ChecklistModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          currentFillOut={currentFillOut}
          refetch={refetch}
        />
      )}

      {currentFillOut && (
        <ConfirmChecklistModal
          isOpen={confirmModal !== ""}
          onClose={() => setConfirmModal("")}
          currentFillOut={currentFillOut}
          handleDelete={handleDelete}
          type={confirmModal}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default ChecklistTable;
