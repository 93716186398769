import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { TrainsGroupedBySeries } from "features/CenterContent/RoleContent/AffectedTrains/utils/groupTrainSeries";
import { findRepresentativeTrainId } from "features/CenterContent/RoleContent/AffectedTrains/utils/utils";
import { InternalMessagesForm } from "features/CenterContent/RoleContent/InternalMessage/types";
import {
  internalMessagesFormDefault,
  internalMessagesFormToRequest,
  internalMessagesResponseToForm,
} from "features/CenterContent/RoleContent/InternalMessage/utils/form";
import { FormSchema as InfrastructureFormSchema } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { trainIdentifierToOperationalIdentifierJson } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";
import {
  RouteSectionSchema,
  SingleTrainFormSchema,
  TrainFormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import {
  TrainInformationDetailedResponse,
  trainResponseIsTrainCustomResponse,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationDetailedResponse";
import {
  BaseRequest,
  RouteSection,
  TrainInfoRequest,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationRequest";
import { isCustomInputType } from "features/CenterContent/shared/operationalInformation/utils";
import { DefaultValues } from "react-hook-form";
import { UserRole } from "shared/types/roles";
import { RepresentativeTrainIdentifier } from "./TrainInfoBatchModal";

const getTrainStoppedRouteSection = (
  routeSection?: RouteSectionSchema,
): RouteSection =>
  routeSection
    ? {
        fromStop: routeSection.fromStop ?? null,
        toStop: routeSection.type === "AT_STOP" ? null : routeSection.toStop,
      }
    : { fromStop: null, toStop: null };

/*
 * Method for mapping single train form state to the request body (TrainInfoRequest) needed for creating a train info.
 * */
export const singleTrainFormToRequestBody = (
  formState: TrainFormSchema,
  train: TrainIdentifier,
): TrainInfoRequest | null => {
  const baseRequestBody: BaseRequest = {
    trainIdentifier: trainIdentifierToOperationalIdentifierJson(train),
    incidentIds: formState.incidentId ? [formState.incidentId] : undefined,
    reason:
      formState.reason.type === "NO_REASON"
        ? null
        : {
            type: formState.reason.type,
            description:
              isCustomInputType(formState.reason.type) &&
              formState.reason.description
                ? formState.reason.description
                : null,
          },
    action:
      formState.action.type.length > 0
        ? {
            type: formState.action.type,
            description:
              isCustomInputType(formState.action.type) &&
              formState.action.description
                ? formState.action.description
                : null,
          }
        : null,
    // formState.internalMessages is optional in trainForm because it is unused in trainBatchFormModal
    internalMessage: internalMessagesFormToRequest(formState.internalMessages),
  };

  const dropsLogText =
    formState?.type &&
    formState.type !== TrainEventTypeEnum.TRAIN_CUSTOM &&
    formState?.dropsLogText?.enabled
      ? formState.dropsLogText.texts
      : undefined;

  switch (formState?.type) {
    case "TRAIN_STOPPED":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        currentRouteSection: getTrainStoppedRouteSection(
          formState.trainRouteSection,
        ),
        dropsLogText,
      };
    case "TRAIN_CANCELLED":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops: formState.affectedStops,
        dropsLogText,
      };
    case "TRAIN_CAPACITY_REDUCED":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops: formState.affectedStops,
        oldCapacity: formState.oldCapacity,
        newCapacity: formState.newCapacity,
        oldCapacityUnit: formState.newCapacityUnit,
        newCapacityUnit: formState.oldCapacityUnit,
        dropsLogText,
      };
    case "TRAIN_CAPACITY_INCREASED":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops: formState.affectedStops,
        oldCapacity: formState.oldCapacity,
        newCapacity: formState.newCapacity,
        oldCapacityUnit: formState.newCapacityUnit,
        newCapacityUnit: formState.oldCapacityUnit,
        dropsLogText,
      };
    case "TRAIN_DELAYED":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops: formState.affectedStops,
        delayInMinutes: formState.delayInMinutes,
        dropsLogText,
      };
    case "TRAIN_DELAY_EXPECTED":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops: formState.affectedStops,
        delayInMinutes: formState.delayInMinutes,
        dropsLogText,
      };
    case "TRAIN_LATE_TO_TRACK":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops: formState.affectedStops,
        minutesLate: formState.minutesLate,
        dropsLogText,
      };
    case "TRAIN_NOT_STOPPING_AT_STATION":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        dropsLogText,
        affectedStops: formState.skippedStops,
      };
    case "TRAIN_MISSING_PRODUCT":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops: formState.affectedStops,
        products: formState.products,
        coverage: formState.coverage,
        dropsLogText,
      };
    case "TRAIN_STOPPING_EXTRA_AT_STATION":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        dropsLogText,
        affectedStops: formState.extraStops,
      };
    case "TRAIN_CLOSED_SET":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops: formState.affectedStops,
        dropsLogText,
      };
    case "TRAIN_GENERAL":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops: formState.affectedStops,
        severity: formState.severity,
        distributions: formState.distributions,
      };
    case "TRAIN_CHANGED_ROUTE":
      return {
        ...baseRequestBody,
        trainInformationType: formState.type,
        affectedStops: formState.affectedStops,
        newRoute: formState.newRoute,
        cancelledStops: formState.cancelledStops,
        originalRouteName: formState.originalRouteName,
        newRouteName: formState.newRouteName,
        dropsLogText,
      };
    default:
      return null;
  }
};

export function trainDetailedResponseToForm(
  trainResponse: TrainInformationDetailedResponse,
): TrainFormSchema {
  const {
    type: responseType,
    dropsLogText: responseDropsLogText,
    incidentIds: responseIncidentIds,
    internalMessages: responseInternalMessages,
    ...response
  } = trainResponse;
  // Opinfo has different types for TRAIN_GENERAL_INFO and _WARNING, but for UX reasons
  // the modal form handles them as the same event with severity as a different field.
  // Therefore, we have to consolidate the type here to be able to parse those events.
  const type: TrainFormSchema["type"] = trainResponseIsTrainCustomResponse(
    trainResponse,
  )
    ? TrainEventTypeEnum.TRAIN_CUSTOM
    : (responseType as TrainEventTypeEnum);

  // The zod form has an extra state field to control whether to show the drops log
  // text field, so we have to infer that state here
  const dropsLogText = trainResponseIsTrainCustomResponse(trainResponse)
    ? { enabled: false, texts: { NOB: "" } }
    : {
        enabled: !!responseDropsLogText?.NOB,
        texts: responseDropsLogText ?? { NOB: "" },
      };

  // Translate from incidentIds to incidentId
  const incidentId = responseIncidentIds?.length
    ? responseIncidentIds[0]
    : undefined;

  const internalMessages: InternalMessagesForm = internalMessagesResponseToForm(
    responseInternalMessages,
  );

  // Ideally this response should be parsed using trainFormSchema.parse,
  // but as long as event can be used to create or edit event
  // we can't, because some fields that are optional in event are required
  // in our schema, i.e. reason and affectedStops
  return {
    ...response,
    type,
    dropsLogText,
    incidentId,
    internalMessages,
  } as TrainFormSchema;
}

export const defaultFormSchema = (
  role: UserRole,
): DefaultValues<SingleTrainFormSchema> => {
  if (role === UserRole.lokleder1 || role === UserRole.lokleder2) {
    return {
      trainForm: {
        type: TrainEventTypeEnum.TRAIN_STOPPED,
        reason: { type: "TECHNICAL_FAULT" },
        action: { type: "" },
        dropsLogText: { enabled: true, texts: { NOB: "" } },
        internalMessages: internalMessagesFormDefault(),
      },
    };
  }
  return {
    trainForm: {
      type: undefined,
      reason: { type: "" },
      action: { type: "" },
      dropsLogText: { enabled: false, texts: { NOB: "" } },
      internalMessages: internalMessagesFormDefault(),
    },
  };
};

type TrainSchema = TrainFormSchema;
type InfraSchema = InfrastructureFormSchema["infrastructureForm"];
export const formStateHasCustomField = (formState: TrainSchema | InfraSchema) =>
  (formState as TrainSchema)?.type === "TRAIN_GENERAL" ||
  formState?.action?.type === "CUSTOM" ||
  formState?.reason?.type === "CUSTOM" ||
  (formState as InfraSchema)?.consequence?.type === "CUSTOM";
export const safeCapitalise = (text: string): string => {
  if (!text || text.length === 0) {
    return "";
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getDirectionRelativeRepresentativeTrain = (
  affectedTrain: AffectedTrain,
  representativeTrain: AffectedTrain,
) =>
  parseInt(affectedTrain.trainId.identifier, 10) % 2 ===
  parseInt(representativeTrain.trainId.identifier, 10) % 2
    ? "ongoing"
    : "oncoming";

export const getRepresentativeTrainIds = (
  allTrainSeries: TrainsGroupedBySeries,
) => {
  const even = Object.values(allTrainSeries).map((it) =>
    findRepresentativeTrainId(it, "even"),
  );
  const odd = Object.values(allTrainSeries).map((it) =>
    findRepresentativeTrainId(it, "odd"),
  );
  const representativeTrainIds = [...even, ...odd].filter(
    Boolean,
  ) as RepresentativeTrainIdentifier[];
  const trainIds: TrainIdentifier[] = representativeTrainIds.map((it) => ({
    identifier: it.trainId,
    nominalDate: it.nominalDate,
    countryCode: "NO",
  }));
  return trainIds;
};
