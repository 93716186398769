import {
  CalendarDateTime,
  fromDate,
  getLocalTimeZone,
} from "@internationalized/date";
import {
  InternalMessageRequest,
  internalMessagesSchema,
} from "features/CenterContent/RoleContent/InternalMessage/types";
import { CountryCode } from "shared/types/countryCode";
import { z } from "zod";

const openEndedVisibilitySchema = z.object({
  type: z.literal("OPEN_ENDED"),
  fromTime: z.coerce
    .date()
    .transform((time) => fromDate(time, getLocalTimeZone())),
});

const timedVisibilitySchema = z.object({
  type: z.literal("TIMED"),
  fromTime: z.coerce
    .date()
    .transform((time) => fromDate(time, getLocalTimeZone())),
  toTime: z.coerce
    .date()
    .transform((time) => fromDate(time, getLocalTimeZone())),
});

const constantVisibilitySchema = z.object({
  type: z.literal("CONSTANT"),
  visible: z.boolean(),
});

export const visibilitySchema = z.discriminatedUnion("type", [
  openEndedVisibilitySchema,
  timedVisibilitySchema,
  constantVisibilitySchema,
]);

export type Visibility = z.infer<typeof visibilitySchema>;

const textSchema = z.object({
  title: z.string(),
  description: z.string(),
});

const textMapSchema = z.object({
  NOB: textSchema.superRefine((val, ctx) => {
    const titleLength = val.title.length;
    const descriptionLength = val.description.length;
    if (titleLength === 0) {
      ctx.addIssue({
        type: "string",
        code: "too_small",
        path: ["title"],
        message: "Du må skrive en tittel for å kunne opprette hendelsen",
        minimum: 1,
        inclusive: true,
      });
    }
    if (descriptionLength === 0) {
      ctx.addIssue({
        type: "string",
        code: "too_small",
        path: ["description"],
        message: "Du må skrive en melding for å kunne opprette hendelsen",
        minimum: 1,
        inclusive: true,
      });
    }
    return titleLength > 0 && descriptionLength > 0;
  }),
  ENG: textSchema.optional().superRefine((val, ctx) => {
    const titleLength = val?.title.length ?? 0;
    const descriptionLength = val?.description.length ?? 0;
    if (
      (titleLength > 0 && descriptionLength > 0) ||
      (titleLength === 0 && descriptionLength === 0)
    ) {
      return true;
    }
    if (titleLength > 0) {
      ctx.addIssue({
        type: "string",
        code: "too_small",
        path: ["description"],
        message: "Du må skrive en melding for å kunne opprette hendelsen",
        minimum: 1,
        inclusive: true,
      });
    }
    if (descriptionLength > 0) {
      ctx.addIssue({
        type: "string",
        code: "too_small",
        path: ["title"],
        message: "Du må skrive en tittel for å kunne opprette hendelsen",
        minimum: 1,
        inclusive: true,
      });
    }
    return false;
  }),
});

export type TextMap = z.infer<typeof textMapSchema>;

export const customTextSchema = z.object({
  contexts: z.array(z.string()),
  texts: textMapSchema,
});

export type CustomTexts = z.infer<typeof customTextSchema>;

export const globalEventSchema = z.object({
  type: z.string(),
  uuid: z.string(),
  updatedAt: z.coerce
    .date()
    .transform((time) => fromDate(time, getLocalTimeZone())),
  visibility: visibilitySchema,
  referencedIncidentIds: z.array(z.string()),
  customTexts: customTextSchema.array(),
  internalMessages: z.array(z.any()).nullish(), // this is immediately converted to InternalMessagesForm, so making a whole zodSchema for this is unnecessary
  // nullish incase internalMessages is not provided and a .parse is ran
});

export type GlobalEvent = z.infer<typeof globalEventSchema>;

export const globalEventsResponseSchema = z.array(globalEventSchema);

const openEndedVisibilityFormSchema = z.object({
  type: z.literal("OPEN_ENDED"),
  fromTime: z.custom<CalendarDateTime>(),
});

export type VisibilityFormSchema = z.infer<
  typeof openEndedVisibilityFormSchema
>;

export const globalEventFormSchema = z.object({
  customTexts: customTextSchema,
  visibility: openEndedVisibilityFormSchema,
  incidentIds: z.array(z.string()),
  internalMessages: internalMessagesSchema,
});

export const globalFormSchema = z.object({
  globalForm: globalEventFormSchema,
});

export type GlobalFormSchema = z.infer<typeof globalFormSchema>;

const constantVisibilityRequest = z.object({
  type: z.literal("CONSTANT"),
  visible: z.boolean(),
});

const openEndedVisibilityRequest = z.object({
  type: z.literal("OPEN_ENDED"),
  fromTime: z.string(),
});

const timedVisibilityRequest = z.object({
  type: z.literal("TIMED"),
  fromTime: z.string(),
  toTime: z.string(),
});

const visibilityRequest = z.discriminatedUnion("type", [
  constantVisibilityRequest,
  openEndedVisibilityRequest,
  timedVisibilityRequest,
]);

export type VisibilityRequest = z.infer<typeof visibilityRequest>;

export type GlobalEventRequest = {
  globalInformationType: "GLOBAL_GENERAL_WARNING";
  visibility: VisibilityRequest;
  incidentIds: string[];
  countryCode: CountryCode;
  customTexts: CustomTexts[];
  internalMessage: InternalMessageRequest | undefined;
};
