import colors from "shared/theme/colors";

export const darkTheme = {
  themeName: "dark",

  /// ///////////////////////////////////////
  // Use SPOR components and it's color variables if possible
  // If component is custom or overwritten, use the following color variables
  // Don't create color variables inside your custom components;
  // Create them here, supported by appPalette.ts
  // Don't even think about using a color without a variable
  /// ///////////////////////////////////////

  // BACKGROUND
  colorBackgroundMain: colors.interstellar, // update to the real Interstellar when spor tokens is updated
  colorBackgroundSecondary: colors.new_night, // update to the real Night when spor tokens is updated
  colorBackgroundTertiary: colors.jungle, // update to the real Jungle when spor tokens is updated
  colorHoverItem: colors.jungle, // update to the real Jungle when spor tokens is updated

  // TEXT
  colorTextMain: colors.white,
  colorTextSecondary: colors.seaMist,
  colorTextDisabled: colors.whiteAlpha["400"],
  colorSelectedText: colors.white,

  // OUTLINES
  colorSeparationLine: colors.whiteAlpha["200"],
  colorOutline: colors.whiteAlpha["400"],
  colorBorder: colors.jungle, // update to the real Jungle when spor tokens is updated

  // SEMANTICS
  // Alarm 1
  colorAlarm: colors.rose,
  colorAlarmPulse: colors.raspberry,
  colorAlarmFill: colors.burgundy, // update to the real Burgundy when spor tokens is updated
  colorAlarmFillPulse: colors.maroon, // update to the real Maroon when spor tokens is updated
  colorAlarmOutline: colors.crimson, // update to the real Crimson when spor tokens is updated
  colorAlarmTextMain: colors.pink,
  colorAlarmTextSecondary: colors.salmon,
  colorAlarmTextTertiary: colors.pink,

  // Alarm 2
  colorSecondaryAlarm: colors.saffron,
  colorSecondaryAlarmPulse: colors.pumpkin,
  colorSecondaryAlarmFill: colors.new_chocolate, // update to the real Chocolate when spor tokens is updated
  colorSecondaryAlarmFillPulse: colors.toast, // update to the real Toast when spor tokens is updated
  colorSecondaryAlarmOutline: colors.russet, // update to the real Russet when spor tokens is updated
  colorSecondaryAlarmTextMain: colors.bisque,
  colorSecondaryAlarmTextSecondary: colors.rajah,
  colorSecondaryAlarmTextTertiary: colors.toast, // update to the real Toast when spor tokens is updated

  // Warning
  colorWarning: colors.banana,
  colorWarningPulse: colors.burntYellow,
  colorWarningFill: colors.coffee, // update to the real Coffee when spor tokens is updated
  colorWarningFillPulse: colors.olive, // update to the real Olive when spor tokens is updated
  colorWarningOutline: colors.bronce, // update to the real Bronce when spor tokens is updated
  colorWarningTextMain: colors.cornsilk,
  colorWarningTextSecondary: colors.primrose,
  colorWarningTextTertiary: colors.coffee, // update to the real Coffee when spor tokens is updated

  // Success
  colorSuccess: colors.blueGreen,
  colorSuccessPulse: colors.azure,
  colorSuccessFill: colors.darkTeal,
  colorSuccessFillPulse: colors.night,
  colorSuccessOutline: colors.pine,
  colorSuccessTextMain: colors.mint,
  colorSuccessTextSecondary: colors.coralGreen,
  colorSuccessTextTertiary: colors.night,

  // Info
  colorInfo: colors.blueberry,
  colorInfoPulse: colors.ocean,
  colorInfoFill: colors.navy, // update to the real Navy when spor tokens is updated
  colorInfoFillPulse: colors.royal, // update to the real Royal when spor tokens is updated
  colorInfoOutline: colors.darkBlue,
  colorInfoTextMain: colors.icyBlue,
  colorInfoTextSecondary: colors.cloudy,
  colorInfoTextTertiary: colors.icyBlue,

  // Neutral
  colorNeutral: colors.silver,
  colorNeutralPulse: colors.steel,
  colorNeutralFill: colors.darkGrey,
  colorNeutralFillPulse: colors.carbon, // update to the real Carbon when spor tokens is updated
  colorNeutralOutline: colors.darkerGrey,
  colorNeutralTextMain: colors.platinum,
  colorNeutralTextSecondary: colors.steel,
  colorNeutralTextTertiary: colors.lightGrey,

  // SPECIALS
  trainMapTopology: colors.pine,
  colorSelected: colors.darkTeal,
  skeletonElementColor: colors.nightLighter,
  boxShadow: "0px 2px 24px rgba(0, 0, 0, 0.4)",
};

export type DarkTheme = typeof darkTheme;
