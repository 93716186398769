import { WarningOutline18Icon } from "@vygruppen/spor-icon-react";
import { HStack, Stack, Text } from "@vygruppen/spor-react";
import { GlobalEventModalContent } from "features/CenterContent/RoleContent/GlobalEvents/GlobalEventModalContent";
import { GlobalFormSchema } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { EditGlobalEventChekcbox } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/EditGlobalEventChekcbox";
import { FC, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { ChevronDownButton } from "shared/components/buttons/ChevronButton";
import { PlusButton } from "shared/components/buttons/PlusButton";
import { useTheme } from "styled-components";

type ReferencedGlobalEventProps = {
  mode: "edit" | "create";
  globalEventFormMethods: UseFormReturn<GlobalFormSchema>;
  setShouldPostGlobalEvent: (value: boolean) => void;
};

export const ReferencedGlobalEvent: FC<ReferencedGlobalEventProps> = ({
  mode,
  globalEventFormMethods,
  setShouldPostGlobalEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editChecked, setEditChecked] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (mode === "create") {
      setShouldPostGlobalEvent(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (mode === "edit") {
      setShouldPostGlobalEvent(editChecked);
    }
  }, [editChecked]);

  return (
    <Stack
      width="100%"
      gap={4}
      paddingX={3}
      paddingY={2}
      boxShadow={`inset 0 0 0 1px ${theme.colorSeparationLine}`}
      borderRadius="sm"
    >
      <HStack justifyContent="space-between">
        <HStack>
          <WarningOutline18Icon />
          <Text variant="xs" fontWeight="bold">
            Global driftsmelding
          </Text>
        </HStack>
        {mode === "edit" ? (
          <ChevronDownButton active={isOpen} setActive={setIsOpen} />
        ) : (
          <PlusButton active={isOpen} setActive={setIsOpen} />
        )}
      </HStack>
      {isOpen && (
        <>
          {mode === "edit" && (
            <EditGlobalEventChekcbox
              editGlobalEvent={editChecked}
              setEditGlobalEvent={setEditChecked}
            />
          )}
          <GlobalEventModalContent
            formMethods={globalEventFormMethods}
            isDisabled={mode === "edit" && !editChecked}
          />
        </>
      )}
    </Stack>
  );
};
