import { TabPanel, TabPanels, Tabs } from "@vygruppen/spor-react";
import { InternalMessageResponse } from "features/CenterContent/RoleContent/InternalMessage/types";
import { InternalMessageEventsList } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/InternalMessageEventsList";

type InternalMessageEventsProps = {
  data: InternalMessageResponse[];
  onEditClick: (event: InternalMessageResponse) => void;
};

export const InternalMessageEvents = ({
  data,
  onEditClick,
}: InternalMessageEventsProps) => (
  <Tabs
    variant="base"
    colorScheme="base"
    isFitted
    gap={3}
    size="sm"
    marginTop={3}
  >
    <TabPanels>
      <TabPanel>
        <InternalMessageEventsList data={data} onEditClick={onEditClick} />
      </TabPanel>
    </TabPanels>
  </Tabs>
);
