import { Tooltip } from "@chakra-ui/react";
import { InformationOutline24Icon } from "@vygruppen/spor-icon-react";
import { Box, Flex, Skeleton, Text } from "@vygruppen/spor-react";
import { Dispatch, SetStateAction, useRef } from "react";
import { useIsTruncated } from "shared/components/dataDisplay/useIsTruncated";
import styled, { useTheme } from "styled-components";
import { ArrowIcon } from "../../../../icons/ArrowIcon";
import { Severity, SeverityIcon, severitySubtitleColor } from "../severity";
import { BORDER_RADIUS, COLUMN_GAP } from "../style";

const Title = styled.h3`
  padding: 0;
  margin: 0;
  font-family: "Vy Sans", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.33rem;
  text-align: start;
`;

const Subtitle = styled(Text)`
  padding: 0;
  margin: 0;
  font-family: "Vy Sans", sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.33rem;
  text-align: start;

  /* Truncation: */
  display: block;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`;

const ButtonWrapper = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  outline: none;
  width: 100%;
  border-radius: ${BORDER_RADIUS}px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-items: start;
  align-items: center;
  column-gap: ${COLUMN_GAP}px;
  grid-column: 1 / span 2;
  grid-row: 1;

  &:focus {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.__focusBorderColor};
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.__focusBorderColor};
  }
`;

type ExpandableButtonProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  severity: Severity;
  title: string;
  subTitle?: string;
  tooltip?: string;
  iconColorOverride?: string;
  skeleton?: boolean;
  showExpandWhenTruncated?: boolean;
};

export const ExpandableButton = ({
  isOpen,
  setIsOpen,
  severity,
  title,
  subTitle,
  tooltip,
  iconColorOverride,
  skeleton,
  showExpandWhenTruncated,
}: ExpandableButtonProps) => {
  const theme = useTheme();
  const textRef = useRef<HTMLElement>(null);
  const isTruncated = useIsTruncated(textRef, subTitle, isOpen);
  const enabled = showExpandWhenTruncated ? isOpen || isTruncated : true;

  return (
    <ButtonWrapper
      type="button"
      aria-expanded={isOpen}
      aria-controls="collapsible_info_message_content"
      disabled={!enabled}
      onClick={() => setIsOpen(!isOpen)}
    >
      {!tooltip && (
        <SeverityIcon severity={severity} fill={iconColorOverride} />
      )}
      <Flex gridColumn={2} gap={1} alignItems="center">
        <Title>{title}</Title>
        {tooltip && (
          <Tooltip
            hasArrow
            label={tooltip}
            placement="right"
            shouldWrapChildren
          >
            <InformationOutline24Icon />
          </Tooltip>
        )}
      </Flex>
      <Box
        w="100%"
        overflow="hidden"
        gridColumn={2}
        gridRow={2}
        mt={tooltip ? "6px" : "0px"}
      >
        {skeleton ? (
          <Skeleton height={3} width="100%" />
        ) : (
          <Subtitle
            ref={textRef}
            variant="xs"
            color={severitySubtitleColor(severity, theme)}
          >
            {subTitle}
          </Subtitle>
        )}
      </Box>
      {enabled && (
        <ArrowIcon
          color={severitySubtitleColor(severity, theme)}
          rotate={isOpen ? -180 : 0}
        />
      )}
    </ButtonWrapper>
  );
};
