import {
  CloseOutline30Icon,
  IconsOutline30Icon,
} from "@vygruppen/spor-icon-react";
import { Box, Flex, IconButton, Text } from "@vygruppen/spor-react";
import { trainIdKey } from "features/CenterContent/RoleContent/AffectedTrains/utils/utils";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import styled from "styled-components";
import { ColorMode, Divider, useColorMode } from "@chakra-ui/react";
import { TrainResumedElement } from "./TrainResumedElement";
import { TrainResumed } from "./useTrainResumed";

const AlertsContainer = styled.div<{ $colorMode: ColorMode }>`
  display: flex;
  width: 430px;
  min-height: 5rem;
  flex-direction: column;
  background-color: ${({ theme, $colorMode }) =>
    $colorMode === "dark"
      ? theme.colorBackgroundTertiary
      : theme.colorBackgroundSecondary};
  border-radius: 6px;
  box-sizing: border-box;
  z-index: 1;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

const TrainResumedContainer = styled.div`
  ${ObtrusiveScrollCss};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 6px;
  max-height: 55vh;
`;

export const AlertsModal = ({
  trainsResumed,
  setTrainsResumed,
  setShowModal,
}: {
  trainsResumed: TrainResumed[];
  setTrainsResumed: (value: TrainResumed[]) => void;
  setShowModal: (value: boolean) => void;
}) => {
  const { colorMode } = useColorMode();

  return (
    <div
      style={{
        marginTop: "48px",
        marginLeft: "66px",
        position: "absolute",
        transform: "translateX(-100%)",
      }}
    >
      <AlertsContainer $colorMode={colorMode}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          px={1.5}
          pb={1}
          pt={1.5}
        >
          <Text fontWeight="bold">Oppdaterte hendelser</Text>
          <IconButton
            variant="ghost"
            aria-label="close"
            icon={<CloseOutline30Icon />}
            onClick={() => {
              setShowModal(false);
            }}
          />
        </Flex>
        <Divider />
        <Box pl={0}>
          {trainsResumed.length === 0 && (
            <Flex alignItems="center" gap={1}>
              <IconsOutline30Icon />
              <Text>Her var det ryddig og fint!</Text>
            </Flex>
          )}
          <TrainResumedContainer>
            {trainsResumed.map((trainResumed) => (
              <TrainResumedElement
                key={trainIdKey(trainResumed.trainIdentifier)}
                trainResumed={trainResumed}
                trainResumedIsFinished={() =>
                  setTrainsResumed(
                    trainsResumed.filter((it) => trainResumed !== it),
                  )
                }
              />
            ))}
          </TrainResumedContainer>
        </Box>
      </AlertsContainer>
    </div>
  );
};
