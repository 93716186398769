import { Text } from "@vygruppen/spor-react";
import { TrainInformationAggregated } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationAggregated";
import { FC } from "react";
import {
  Severity,
  severityBorderColor,
  severitySubtitleColor,
} from "shared/components/feedback/InfoMessage/severity";
import { formatDayDateString } from "shared/utils/datetime";
import { useTheme } from "styled-components";

type TrainInfoVersionsProps = {
  severity: Severity;
  trainInfo: TrainInformationAggregated;
};

export const TrainInfoVersions: FC<TrainInfoVersionsProps> = ({
  severity,
  trainInfo,
}) => {
  const theme = useTheme();

  return (
    <>
      {trainInfo.versions.map((version, index) => (
        <li key={version.updatedAt}>
          <Text variant="xs">{version.message}</Text>
          <Text
            variant="xs"
            mt={2}
            pb={3}
            mb={1}
            borderBottom={`${
              index === trainInfo.versions.length - 1 ? "0px" : "1px"
            } dashed${severityBorderColor(severity, theme)}`}
            textColor={severitySubtitleColor(severity, theme)}
          >
            {formatDayDateString(version.updatedAt)}
          </Text>
        </li>
      ))}
    </>
  );
};
